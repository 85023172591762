import styled from "styled-components";
import formatNumberToString from "../../../utils/formatNumber";

interface Balance {
    positive: number,
    negative: number
}

export default function Header({positive, negative}: Balance) {
    return (
        <Main>
            <div className="general-balance">
                Balance General
            </div>
            <div className="account">
                <span className="positive">$ {formatNumberToString(positive)}</span>
                <span className="negative">$ {formatNumberToString(negative)}</span>
            </div>
            <div className="account">
                <span className="general">
                $ {formatNumberToString(positive + negative)}
                </span>
            </div>
        </Main>
    )
}

const Main = styled.div`
.general-balance {
    padding: 5px 10px;
    font-size: 18px;
    background-color: ${props => props.theme.blueLight};
    text-align: center;
    width: fit-content;
    margin: auto;
    color: ${props => props.theme.white};
}
.account {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 350px;
    margin: 20px auto;
    > span {
        padding: 5px 10px;
        flex: 1;
        text-align: center;
        display: block;
        color: ${props => props.theme.white};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.general {
            width: fit-content;
            background-color: ${props => props.theme.blueLight};
        }
        &.positive {
            background-color: ${props => props.theme.lightGreen};
        }
        &.negative {
            background-color: ${props => props.theme.red};
        }
    }
}
`