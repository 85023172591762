import styled from "styled-components";
import { FiLogOut } from 'react-icons/fi'
import { useNavigate } from "react-router-dom";
import { useAdmin } from "../../context/admin";

export default function Navbar() {
    const {tab, setUser} = useAdmin()
    const router = useNavigate()
    function logout () {
        localStorage.removeItem('admin')
        setUser(null)
        router('/admin/access')
    }
    return (
        <Main>
            <h1>
                {tab}
            </h1>
            <div className="logout" title="logout"
            onClick={logout}
            >
                <FiLogOut/>
            </div>
        </Main>
    )
}

const Main = styled.nav`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
h1 {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}
.logout {
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    &:hover {
        background-color: ${props => props.theme.blackTransparent};
    }
}
`