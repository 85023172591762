import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import { CSSProperties, useState } from "react";

interface OptionCheckInterface {
    options: string[],
    style?: CSSProperties,
    title?: string,
    onSelect: Function
}

export default function Dropdown({
    options,
    style,
    onSelect
}: OptionCheckInterface) {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
    return (
        <Main style={style}>
            <div className="opener"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
                <IoIosArrowDown/>
            </div>
            <div className="options"
            style={{height: isDropdownOpen ? (options.length * 30) + options.length * 2 : 0}}
            >
            {
                options.map((option, idx) => {
                    let isLast = option === options[options.length - 1]
                    let isFirst = option === options[0]
                    return (
                        <div className={`option`} key={idx}
                        onClick={() => onSelect(option)}
                        style={(isLast && isFirst ? {borderRadius: '10px'} : (isLast ? {borderRadius: '0 0 10px 10px'} : (isFirst ? {borderRadius: '10px 10px 0 0'} : {} )))}
                        >
                            {option}
                        </div>
                    )
                })
            }
            </div>
        </Main>
    )
}

const Main = styled.div`
.opener {
    width: 80%;
    margin: auto;
    color: #FFF;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: ${props => props.theme.blueLight};
}
.options {
    transition: height .3s ease;
    overflow: hidden;
}
h3 {
    font-weight: 500;
    margin-bottom: 10px;
}
.option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 0 20px;
    cursor: pointer;
    border: 1px solid #000;
    border-top: none;
    &:first-child {
        border: 1px solid #000;
    }
}
.option.selected {
    border: 1px solid #1fc600;
}
.check {
    color: #1fc600;
}
`