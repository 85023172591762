import React, { ChangeEvent, FormEvent, useEffect, useState, Dispatch, SetStateAction } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { PassengerPlan, Passenger } from '../../../types/common.types'
import GeneralInput from '../../../components/common/GeneralInput'
import { usePassenger } from '../../../context/passenger'
import { mobile } from '../../../styles/responsive'
import CountryInput from '../../common/CountryInput'
import PassengerService from '../../../services/passenger.service'

export default function Info({isInfoOpen, setIsInfoOpen}: {isInfoOpen: boolean | null, setIsInfoOpen: Dispatch<SetStateAction<boolean | null>>}) {
    const {user} = usePassenger()
    const [data, setData] = useState<Passenger>((user?.user?.passenger || {}) as Passenger)
    useEffect(() => {
        if(user?.user?.passenger) setData(user?.user?.passenger)
    }, [user?.user?.passenger])
    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        const name = e.target.name
        setData(prevData => ({...prevData, [name]: value}))
    }
    function onSelectCountry(e: ChangeEvent<HTMLSelectElement>) {
        const name = e.target.name
        const value = e.target.value
        setData((prevData) => ({...prevData, [name]: value}))
    }
    async function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            const newData: Passenger = {
                name: data.name || undefined,
                email: data.email || undefined,
                rut_passport: data.rut_passport || undefined,
                phone: data.phone || undefined,
                age: data.age || undefined,
                hotel: data.hotel || undefined,
                nationality: data.nationality || undefined,
                remark: data.remark || undefined
            } as Passenger
            await PassengerService.editPassenger(newData)
            setIsInfoOpen(false)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Main isInfoOpen={isInfoOpen}
        onSubmit={onSubmit}
        >
            <div className="container">
                <GeneralInput
                name='name'
                label='Name'
                value={data.name || ''}
                onChange={onChange}
                required
                />
                <GeneralInput
                name='email'
                label='Email'
                type='email'
                value={data.email || ''}
                onChange={onChange}
                required
                />
            </div>
            <div className="container">
                <GeneralInput
                name='rut_passport'
                label='RUT/Passport'
                value={data.rut_passport || ''}
                onChange={onChange}
                />
                <GeneralInput
                name='phone'
                label='Phone'
                value={data.phone || ''}
                onChange={onChange}
                />
            </div>
            <div className='container'>
                <GeneralInput
                name='age'
                label='Age'
                type='number'
                value={data.age || ''}
                onChange={onChange}
                />
                <GeneralInput
                name='hotel'
                label='Hotel'
                value={data.hotel || ''}
                onChange={onChange}
                />
            </div>
            <div className='container'>
                <GeneralInput
                name='remark'
                label='Remark'
                value={data.remark || ''}
                onChange={onChange}
                />
                <div className="locations">
                    <CountryInput
                    label='Nationality'
                    value={data.nationality}
                    name='nationality'
                    onChange={onSelectCountry}
                    />  
                </div>
            </div>
            <button 
            type="submit"
            >Submit</button>
        </Main>
    )
}

const Main = styled.form<{isInfoOpen: boolean | null}>`
  overflow: hidden;
  height: ${({isInfoOpen}) => (isInfoOpen ? 'auto' : '0')};
  transition: height 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  .locations {
display: flex;
gap: 20px;
margin-bottom: 20px;
}
  .container {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;

    > div {
        flex: 1;
        min-width: 200px;
    }
  }

  button {
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

button::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

button:hover {
    color: ${props => props.theme.white};
}

button:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

button:hover::before {
    top: -30px;
    left: -30%;
}
`;