function formatNumberToString(number: number): string {
    // Convert number to string with fixed 2 decimal places
    if (!number) return '0,00'
    let formattedNumber = number.toFixed(2);

    // Split the string into integer and decimal parts
    const [integerPart, decimalPart] = formattedNumber.split('.');

    // Insert commas every three digits in the integer part
    let integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Join integer and decimal parts with a comma
    return decimalPart ? `${integerWithCommas},${decimalPart}` : integerWithCommas;
}

export default formatNumberToString;