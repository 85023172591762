import styled from "styled-components";
import { mobile } from "../../../styles/responsive";
import { TripReservation } from "../../../types/common.types";
import { useAdmin } from "../../../context/admin";
import { MdMonetizationOn } from "react-icons/md";
import removeTimezone from "../../../utils/removeTimezone";
import formatNumberToString from "../../../utils/formatNumber";

export default function Trips({list}: {list: TripReservation[]}) {
    const {user, setRegisterCostsTripId} = useAdmin()
    return (
        <Main>
            {
                list.map((trip, idx) => {
                    if (!trip.trip_date) return <></>
                    const agency_pass = trip.reservations.filter(obj => obj.agency.id === user.user.agency_id)
                    const agency_pass_total = agency_pass.map(obj => obj.price).reduce((a,b) => a + b, 0)
                    const agency_traspass = trip.reservations.filter(obj => obj.agency.id !== user.user.agency_id)
                    const agency_traspass_total = agency_traspass.map(obj => obj.traspass_price).reduce((a,b) => a + b, 0)
                    const trip_costs = trip.costs?.map((obj) => obj.value).reduce((a,b) => a +b,0) || 0
                    const commissions = trip.reservations.map(obj => obj.agency.id === user.user.agency_id ? (obj.sale?.amount ? obj.sale.amount : obj.sale ? (obj.price * obj.passenger.seller?.sell_commission / 100 || 0) : 0)  : 0 ).reduce((a,b) => a + b, 0)
                    const date = removeTimezone(trip.trip_date.toString())
                    return (
                        <>
                        <div className="trip">
                            <div className="tour" key={idx}>
                            <div className="header">
                                <div className="name">
                                    <span>
                                        {trip.tour_id} {date}
                                    </span>
                                </div>
                                <div className="actions">
                                    <button className="costs"
                                    title="Register costs"
                                    onClick={() => {
                                        setRegisterCostsTripId(trip.id)
                                    }}
                                    >    
                                        <MdMonetizationOn/>
                                    </button>
                                </div>
                            </div>
                            <TableWrapper>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{width: '40px'}}>
                                            </th>
                                            <th>Name</th>
                                            <th>Traspassed from</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            trip.reservations?.map((reservation, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>
                                                            {idx + 1}
                                                        </td>
                                                        <td>{reservation.passenger.name}</td>
                                                        <td>{user.user.agency_id === reservation.agency.id ? '' : reservation.agency.name}</td>
                                                        <td>{user.user.agency_id === reservation.agency.id ? reservation.price : reservation.traspass_price}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </TableWrapper>
                            </div>
                            <div className="accounting">
                                <div className="costs">
                                    <span>Costos del tour</span>
                                    {
                                        trip.costs?.map((cost, idx) => {
                                            return (
                                                <span key={idx}>{cost.name}: {cost.value}</span>
                                            )
                                        })
                                    }
                                    Costos totales: {trip_costs}
                                </div>
                                <div className="sales">
                                    <span>Venta de pasajeros agencia</span>
                                    <span>{agency_pass.length} pax = {formatNumberToString(agency_pass_total)}</span>
                                    <span>Comisiones: {formatNumberToString(commissions)}</span>
                                    <span>Total agencia: {formatNumberToString(agency_pass_total - commissions)}</span>
                                    <span>Traspasos </span>
                                    <span>{agency_traspass.length} pax =  {formatNumberToString(agency_traspass_total)}</span>
                                </div>
                                <div className="total">
                                    <div className="head">
                                        <span>Costos {formatNumberToString(trip_costs)}</span>
                                        <span>Ingresos {formatNumberToString((agency_pass_total - commissions) + agency_traspass_total)}</span>
                                    </div>
                                    <div className="earnings">
                                        {formatNumberToString(((agency_pass_total - commissions) + agency_traspass_total) - trip_costs)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            list.length - 1 !== idx &&
                            <hr />
                        }
                        </>
                    )
                })
            }
        </Main>
    )
}

const Main = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
width: 90%;
margin: auto;
margin-top: 15px;
${mobile({
    width: '100%'
})}
overflow: unset;
.trip {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.accounting {
    flex: 1;
    display: grid;
    min-width: 300px;
    grid-template-columns: 1fr 1fr;
    .costs {
        grid-column: 1 / 2;
        span {
            display: block;
        }
    }
    .sales {
        grid-column: 2 / 3;
        span {
            display: block;
        }
    }
    .total {
        margin-top: 10px;
        grid-column: 1 / 3;
        .head {
            padding: 5px 10px;
            background-color: ${props => props.theme.blueDark};
            color: ${props => props.theme.white};
            display: flex;
            > span {
                flex: 1;
            }
        }
    }
    .earnings {
        padding: 5px 10px;
        text-align: center;
        color: ${props => props.theme.white};
        background-color: ${props => props.theme.greenDark};
    }
}
.tour {
    flex: 1;
    background-color: ${props => props.theme.whiteBackground};
    border-radius: 10px;
    box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
    .header {
        .name {
            flex: 1;
            text-align: center;
        }
        .actions {
            height: 100%;
            display: flex;
            gap: 5px;
            padding: 5px;
            button {
                height: 100%;
                width: 50px;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                color: ${props => props.theme.white};
            }
            button.costs {
                background-color: ${props => props.theme.lightGreen};
            }
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px 10px 0 0;
        position: relative;
        height: 40px;
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
    }
}
`

const TableWrapper = styled.div`
width: 100%;
overflow: auto;
min-height: 80px;
`

const Table = styled.table`
border-collapse: collapse;
position: relative;
table-layout: fixed;
height: 100%;
width: 100%;
min-width: 350px;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
tr {
    height: 40px;
    width: 100%;
}
`