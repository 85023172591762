import styled from "styled-components";
import { RxAvatar } from "react-icons/rx";
import { FiLogOut } from 'react-icons/fi'
import { useSeller } from "../../context/seller";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
    const {setUser} = useSeller()
    const navigate = useNavigate()
    function logout() {
        localStorage.removeItem('seller')
        setUser(null)
        navigate('/seller/access')
    }
    return (
        <Main>
            <div
            onClick={() => navigate('/seller/me')}
            >
                <RxAvatar/>
            </div>
            <div onClick={logout}>
                <FiLogOut/>
            </div>
        </Main>
    )
}

const Main = styled.nav`
position: fixed;
top: 0;
left: 0;
width: 100%;
padding: 0 20px;
z-index: 10;
font-size: 24px;
gap: 10px;
height: 60px;
display: flex;
align-items: center;
justify-content: flex-end;
> div {
    height: 30px;
    cursor: pointer;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    background-color: white;
    padding: 5px;
    &:hover {
        background-color: ${props => props.theme.blackTransparent};
    }
}
`