import http from "./http-common";

class ToursServiceClass {
    private path = '/tours'
    list() {
        return http.get(this.path)
    }
}

const ToursService = new ToursServiceClass()

export default ToursService;