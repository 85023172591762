import * as CommonTypes from "../types/common.types";
import http from "./http-common";

class SellerServiceClass {
    private token: string | undefined;
    private config: { headers: { Authorization: string | undefined } };
    private seller: string = '/seller'
    private passenger: string = '/passenger'
    private tours: string = '/tours'
    private trips: string = '/trips'
    private guider: string = '/guider'
    private driver: string = '/driver'
    private reservation: string = '/reservation'
    constructor(token?: string) {
        this.token = token;
        this.config = { headers: { Authorization: `Bearer ${this.token}` } };
    }
    
    setToken(token: string) {
      this.token = token;
      this.config = { headers: { Authorization: `Bearer ${this.token}` } };
    }

    login(data: CommonTypes.DefaultLogin) {
      return http.post(this.seller + '/login', data)
    }

    createPassenger(data: Partial<CommonTypes.Passenger>) {
      return http.post(this.passenger + '/create', data, this.config)
    }

    createReservation(data: CommonTypes.SubmitReservation) {
      return http.post(this.reservation + '/create', data, this.config)
    }

    listPassengers() {
      return http.get('/passenger', this.config)
    }

    listTours(date: string) {
      return http.post(this.tours + '/listToursTripsPassengers', {date}, this.config)
    }
    
    getPassengerPlan(id: string) {
      return http.get(this.passenger + `/passenger-plan?passenger_id=${id}`, this.config)
    }

    getReservation(reservation_id: string) {
      return http.get(this.reservation + `/reservation/${reservation_id}`, this.config)
    }

    me() {
      return http.get(this.seller + '/me', this.config)
    }

    editReservation(reservation_id: string, data: CommonTypes.Reservation) {
      return http.put(this.reservation + `/edit/${reservation_id}`, data, this.config)
    }
}

const SellerService = new SellerServiceClass()

export default SellerService