import styled, { keyframes } from "styled-components";
import { useAdmin } from "../../../context/admin";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ChangeEvent, FormEvent, useState } from "react";
import { Payment } from "../../../types/common.types";
import AdminService from "../../../services/admin.service";
import GeneralInput from "../../common/GeneralInput";
import Dropdown from "../../common/Dropdown";
import FileInput from "../../common/FileInput";

export default function AddPayment() {
    const {setAddPaymentData, addPaymentData} = useAdmin()
    const [data, setData] = useState<Payment>(addPaymentData as Payment)
    async function register(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            const formData = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                    let newValue = value
                    formData.append(key, newValue);
            });
            console.log(formData)
            await AdminService.registerPayment(formData)
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const name = e.target.name
        const value = e.target.value
        setData(prevData => ({...prevData, [name]: value}))
    }

    function onChangeFiles(newFile: File | undefined, name: keyof Payment) {
        console.log(newFile)
        setData(prevData => ({...prevData, [name]: newFile}))
    }
    return (
        <Main>
            <Modal
            onSubmit={register}
            >
                <h1>{addPaymentData.type === 'send' ? 'Enviar' : 'Recibir'} </h1>
                <GeneralInput
                label="Amount"
                placeholder="10.000"
                onChange={onChange}
                type="number"
                required
                name="amount"
                />
                <GeneralInput
                label="Remarks"
                onChange={onChange}
                required
                name="remarks"
                style={{margin: '20px 0'}}
                />
                <div className="payment">
                    <h2>Payment method</h2>
                    <h3>{data.payment_method}</h3>
                    <Dropdown
                    title="Payment method"
                    options={['transfer', 'cash']}
                    onSelect={(newValue: string) => setData(prevData => ({...prevData, payment_method: newValue}))}
                    />
                </div>
                <FileInput
                style={{margin: '10px 0'}}
                value={data.pic}
                deleteFile={() => onChangeFiles(undefined, 'pic')}
                accept=".png, .jpg, .jpeg, .heic"
                onChange={(newFile: File) => onChangeFiles(newFile, 'pic')}
                />
                <div className="close" title="close"
                    onClick={() => {
                        setAddPaymentData(null)
                    }}
                >
                    <AiOutlineCloseCircle/>
                </div>
                <button type="submit">Add payment</button>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
    `

const Modal = styled.form`
width: 80%;
max-height: 75%;
min-height: 400px;
overflow: hidden;
overflow-y: unset;
max-width: 700px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
padding-top: 50px;
animation: ${showUpModal} .3s ease forwards;
h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 400;
}
h2 {
    font-size: 20px;
    font-weight: 400;
}
h3 {
    font-size: 16px;
    font-weight: 400;
}

.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
> button {
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

> button::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

> button:hover {
    color: ${props => props.theme.white};
}

> button:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

> button:hover::before {
    top: -30px;
    left: -30%;
}
`