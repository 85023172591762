import AddNew from "./AddNew";
import List from "./List";

export default function Guiders() {
    return (
        <>
            <AddNew/>
            <List/>
        </>
    )
}
