import { useState, ChangeEvent, FormEvent } from "react";
import styled, {keyframes} from "styled-components";
import GeneralInput from "../../common/GeneralInput";
import { Passenger } from "../../../types/common.types";
import CountryInput from "../../common/CountryInput";
import { useAdmin } from "../../../context/admin";
import { AiOutlineCloseCircle } from "react-icons/ai";
import AdminService from "../../../services/admin.service";

export default function EditPassenger() {
    const {editPassengerData, setEditPassengerData} = useAdmin()

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const name = e.target.name
        const value = e.target.value
        setEditPassengerData((prevData: Passenger) => ({...prevData, [name]: value}))
    }
    function onSelectCountry(e: ChangeEvent<HTMLSelectElement>) {
        const name = e.target.name
        const value = e.target.value
        setEditPassengerData((prevData: Passenger) => ({...prevData, [name]: value}))
    }

    async function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            const data = {
                id: editPassengerData.id,
                email: editPassengerData.email || undefined,
                name: editPassengerData.name || undefined,
                nationality: editPassengerData.nationality || undefined,
                rut_passport: editPassengerData.rut_passport || undefined,
                hotel: editPassengerData.hotel || undefined,
                phone: editPassengerData.phone || undefined,
                age: editPassengerData.age || undefined,
                remark: editPassengerData.remark || undefined,
            }
            await AdminService.editPassenger(data)
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Main>
            <Modal onSubmit={onSubmit}>
                <h1>Edit passenger</h1>
                <GeneralInput
                label="Name"
                value={editPassengerData.name || ''}
                onChange={onChange}
                placeholder="Carlos Perez"
                name="name"
                style={{marginBottom: '20px'}}
                />
                <GeneralInput
                label="Email"
                value={editPassengerData.email || ''}
                onChange={onChange}
                placeholder="example@example.com"
                name="email"
                style={{marginBottom: '20px'}}
                />
                <GeneralInput
                label="Phone"
                value={editPassengerData.phone || ''}
                onChange={onChange}
                placeholder="+58 412-0713771"
                name="phone"
                style={{marginBottom: '20px'}}
                />
                <GeneralInput
                label="Rut / Passport"
                value={editPassengerData.rut_passport || ''}
                onChange={onChange}
                placeholder="XXXXXXXX-Z"
                name="rut_passport"
                style={{marginBottom: '20px'}}
                />
                <GeneralInput
                label="Hotel"
                value={editPassengerData.hotel || ''}
                onChange={onChange}
                placeholder="APANATOR"
                name="hotel"
                style={{marginBottom: '20px'}}
                />
                <GeneralInput
                label="Age"
                value={editPassengerData.age || ''}
                onChange={onChange}
                placeholder="18"
                name="age"
                type="number"
                style={{marginBottom: '20px'}}
                />
                <GeneralInput
                label="Remark"
                value={editPassengerData.remark || ''}
                onChange={onChange}
                placeholder="Vegetarian"
                name="remark"
                style={{marginBottom: '20px'}}
                />
                <div className="locations">
                    <CountryInput
                    label="Nationality"
                    name="nationality"
                    value={editPassengerData.nationality}
                    onChange={onSelectCountry}
                    />
                </div>
                <button type="submit">Edit passenger</button>
                <div className="close" title="close"
                onClick={() => setEditPassengerData(false)}
                >
                    <AiOutlineCloseCircle/>
                </div>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
`

const Modal = styled.form`
width: 80%;
max-height: 75%;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
animation: ${showUpModal} .3s ease forwards;
h1 {
    font-size: 24px;
    margin-bottom: 20px;
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
.locations {
display: flex;
gap: 20px;
margin-bottom: 20px;
}

button {
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

button::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

button:hover {
    color: ${props => props.theme.white};
}

button:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

button:hover::before {
    top: -30px;
    left: -30%;
}
`