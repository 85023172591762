import styled from "styled-components";
import * as CommonTypes from "../../../types/common.types"
import { countries } from "countries-list";
// icons
import { MdOutlineAssignmentInd } from "react-icons/md";
import { IoIosSend } from "react-icons/io";
import { FaListUl } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useState } from "react";
import arrayItemCompare from "../../../utils/arrayItemCompare";
import { useAdmin } from "../../../context/admin";
import {add, isBefore, startOfToday} from 'date-fns'
import { mobile } from "../../../styles/responsive";
import { FaTrash } from "react-icons/fa6";
import AdminService from "../../../services/admin.service";
import { useGlobal } from "../../../context/global";

interface ListInterface {
    list: CommonTypes.FullTourInfo[]
}

interface TourInterface {
    tour: CommonTypes.FullTourInfo,
    list: CommonTypes.FullTourInfo[]
}

function Tour({tour, list}: TourInterface) {
    const {setMessage} = useGlobal()
    const { setAssignTripReservationId, setCurrentDay, setEditReservationId, setAssignTripTourId, setReservationDetails, setTraspassTripReservationId, setPassengerPlanId, setTraspassTripTourId} = useAdmin()
    const [selectedReservations, setSelectedReservations] = useState<string[]>([])
    
    const selectAll = (tour_id: string) => {
        const reservations = list.find(item => item.id === tour_id)?.reservations.map(item => item.id)
        if (!reservations) return
        if (arrayItemCompare(reservations, selectedReservations)) setSelectedReservations([])
        else setSelectedReservations(reservations)        
    }

    async function deleteReservation(id: string) {
        try {
            await AdminService.deleteReservation(id)
            await setCurrentDay((prevDay: Date) => add(prevDay, {days: 1}))
            await setCurrentDay((prevDay: Date) => add(prevDay, {days: -1}))
            setMessage({
                type: 'success',
                content: 'Reservation successfully deleted'
            })
            setTimeout(() => setMessage(null), 2500)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="tour">
            <div className="header">
                <div className="name">
                    <span>
                        {tour.id}
                    </span>
                </div>
                <div className="actions">
                    <button className="assign"
                    title="Assign"
                    onClick={() => {
                        setAssignTripReservationId(selectedReservations)
                        setAssignTripTourId(tour.id)
                    }}
                    >
                        <MdOutlineAssignmentInd/>
                    </button>
                    <button className="details"
                    title="Ver detalles"
                    onClick={() => {
                        setReservationDetails(tour.reservations)
                    }}
                    >
                        <FaListUl/>
                    </button>
                    <button className="traspax"
                    title="Traspass"
                    onClick={() => {
                        setTraspassTripReservationId(selectedReservations)
                        setTraspassTripTourId(tour.id)
                    }}
                    >    
                        <IoIosSend/>
                    </button>
                </div>
            </div>
            <TableWrapper>
                <Table>
                    <thead>
                        <tr>
                            <th style={{width: '40px'}}>
                                <button
                                className={selectedReservations.length ? arrayItemCompare(selectedReservations, tour.reservations?.map(item => item.id)) ? 'selected-all' : '': ''}
                                onClick={() => selectAll(tour.id)}
                                ></button>
                            </th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Age</th>
                            <th>Born</th>
                            <th>Seller</th>
                            <th>Depart</th>
                            <th>Agency Trip</th>
                            <th>Price</th>
                            <th>Traspass Price</th>
                            <th style={{width: '40px'}}
                            ></th>
                            <th style={{width: '40px'}}
                            ></th>
                            <th style={{width: '40px'}}
                            ></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tour.reservations?.map((reservation, idx) => {
                                const isSelected = selectedReservations.find(id => id === reservation.id)
                            return (
                                    <tr key={idx}>
                                        <td>
                                            <button
                                            className={`select ${isSelected ? 'selected' : ''}`}
                                            onClick={() => {
                                                if (selectedReservations.find(id => id === reservation.id)) {
                                                    const newList = selectedReservations.filter(id => id !== reservation.id)
                                                    setSelectedReservations(newList)
                                                } else setSelectedReservations([...selectedReservations, reservation.id])
                                            }}
                                            ></button>
                                        </td>
                                        <td>{reservation.passenger.name}</td>
                                        <td><a href={`mailto:${reservation.passenger.email}`}>{reservation.passenger.email}</a></td>
                                        <td>{reservation.passenger.age} y/o</td>
                                        <td>{countries[reservation.passenger.nationality as keyof typeof countries].name}</td>
                                        <td>{reservation.passenger.seller?.name}</td>
                                        <td>{reservation.trip?.trip_time}</td>
                                        <td>{reservation.trip?.agency?.name}</td>
                                        <td>{reservation.price}</td>
                                        <td>{reservation.traspass_price}</td>
                                        <td><button className="info" onClick={() => setPassengerPlanId(reservation.passenger_id)}><AiOutlineInfoCircle/></button></td>
                                        <td><button className="info" onClick={() => setEditReservationId(reservation.id)}><MdEdit/></button></td>
                                        <td><button className="info" onClick={() => deleteReservation(reservation.id)}><FaTrash/></button></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </TableWrapper>
        </div>
    )
}

export default function List({
    list
}: ListInterface) {

    
    return (
        <Main>
            {
                list.map((tour, idx) => {
                    return (
                        <Tour tour={tour} list={list} key={idx}/>
                    )
                })
            }
        </Main>
    )
}

const Main = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
width: 90%;
margin: auto;
margin-top: 15px;
max-height: calc(80vh - 90px);
${mobile({
    width: '100%'
})}
overflow: unset;
.tour {
    background-color: ${props => props.theme.whiteBackground};
    border-radius: 10px;
    box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
    .header {
        .name {
            flex: 1;
            text-align: center;
        }
        .actions {
            height: 100%;
            display: flex;
            gap: 5px;
            padding: 5px;
            button {
                height: 100%;
                width: 50px;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                color: ${props => props.theme.white};
            }
            button.traspax {
                background-color: ${props => props.theme.blue};
            }
            button.details {
                background-color: ${props => props.theme.yellowDark};
            }
            button.assign {
                background-color: ${props => props.theme.lightGreen};
            }
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px 10px 0 0;
        position: relative;
        height: 40px;
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
    }
}
`

const TableWrapper = styled.div`
width: 100%;
height: 100%;
overflow: hidden;
overflow-x: unset;
`

const Table = styled.table`
border-collapse: collapse;
position: relative;
table-layout: fixed;
height: 100%;
width: 100%;
min-width: 500px;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    > button {
        width: 20px;
        height: 20px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
            }
    }
    >button.selected-all {
        background-color: ${props => props.theme.yellowDark};
    }
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .flag {
        width: 30px;
    }
    button.select {
        width: 20px;
        height: 20px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
        }
    }
    button.selected {
        background-color: ${props => props.theme.yellowDark};
    }
    button.info {
        border: none;
        background-color: none;
        font-size: 16px;
        cursor: pointer;
    }
}
tr {
    height: 40px;
    width: 100%;
}
`