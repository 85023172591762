import Filter from "./Filter";
import Accounting from "./Acconting";
import Trips from "./Trips";
import { useEffect, useMemo, useState } from "react";
import { TripReservation } from "../../../types/common.types";
import { useAdmin } from "../../../context/admin";
import AdminService from "../../../services/admin.service";
import styled from "styled-components";
import Traspass from "./Traspass";

export default function Earnings() {
    const {currentDay, endDay, user} = useAdmin()
    const [trips, setTrips] = useState<TripReservation[]>([])
    const [traspass, setTraspass] = useState<TripReservation[]>([])
    const [type, setType] = useState<string>('all')
    useEffect(() => {
        async function getData() {
            try {
                if (!currentDay) return
                const response = await AdminService.listEarnings(currentDay, endDay)
                setTrips(response.data.trips)
                setTraspass(response.data.traspass)
                console.log(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        getData()
    }, [currentDay, endDay])

    const total_income = useMemo(() => {
        // traspass
        let agency_sale = 0
        traspass.forEach(obj => agency_sale += obj.reservations.map(obj => obj.price).reduce((a,b) => a + b, 0))
        let agency_commission = 0 
        traspass.forEach(obj => agency_commission +=  obj.reservations.map(obj => obj.sale?.amount || (obj.price * obj.passenger.seller?.sell_commission / 100) || 0).reduce((a,b) => a + b, 0))
        let traspass_cost = 0 
        traspass.forEach(obj => traspass_cost += obj.reservations.map(obj => obj.traspass_price).reduce((a,b) => a + b, 0))
        // trips
        let agency_pass = 0 
        trips.forEach(obj => agency_pass += obj.reservations.filter(obj => obj.agency.id === user.user.agency_id).map(obj => obj.price).reduce((a,b) => a + b, 0))

        let agency_traspass = 0 
        trips.forEach(obj => agency_traspass += obj.reservations.filter(obj => obj.agency.id !== user.user.agency_id).map(obj => obj.traspass_price).reduce((a,b) => a + b, 0))
        let trip_costs = 0 
        trips.forEach(obj => trip_costs += obj.costs?.map((obj) => obj.value).reduce((a,b) => a +b,0) || 0)

        let total = 0
        if (type === 'all') total = (agency_sale - traspass_cost) + (agency_traspass + agency_pass - trip_costs)
        else if (type === 'trips') total = agency_traspass + agency_pass - trip_costs
        else if (type === 'traspass') total = agency_sale - traspass_cost
        return total
    }, [trips, traspass, type, user])
    
    const total_commission = useMemo(() => {
        // traspass
        let agency_commission = 0 
        traspass.forEach(obj => agency_commission +=  obj.reservations.map(obj => obj.sale?.amount || obj.sale ? (obj.price * obj.passenger.seller?.sell_commission / 100) : 0 || 0).reduce((a,b) => a + b, 0))
        // trips
        let commissions = 0 
        trips.forEach(obj => commissions += obj.reservations.map(obj => obj.agency.id === user.user.agency_id ? (obj.sale?.amount ? obj.sale.amount : obj.sale ? (obj.price * obj.passenger.seller?.sell_commission / 100 || 0) : 0)  : 0 ).reduce((a,b) => a + b, 0))
        let total = 0
        if (type === 'all') total = agency_commission + commissions
        if (type === 'trips') total = commissions
        if (type === 'traspass') total = agency_commission
        return total
    }, [trips, traspass, type])

    return (
        <Main>
            <Filter
            setType={setType}
            />
            <Accounting
            total_income={total_income}
            total_commission={total_commission}
            />
            {
                (type === 'trips' || type === 'all') &&
                <Trips
                list={trips}
                />
            }
            {
                (type === 'traspass' || type === 'all') &&
                traspass.length > 0 &&
                <span>
                    CLIENTES NO OPERADOS (traspasos)
                </span>
            }
            {
                (type === 'traspass' || type === 'all') &&
                <Traspass
                list={traspass}
                />
            }
        </Main>
    )
}

const Main = styled.div`
> span {
    display: block;
    margin: 20px auto;
    width: fit-content;
}
`