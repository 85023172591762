import styled, { keyframes } from "styled-components";
import { AiOutlineCloseCircle } from "react-icons/ai"
import { useAdmin } from "../../../context/admin";
import { useEffect, useState } from "react";
import AdminService from "../../../services/admin.service";
import * as CommonTypes from '../../../types/common.types'
import Checkbox from "../../common/Checkbox";
import * as XLSX from 'xlsx';
import objectToArray from "../../../utils/objectToArray";
import { format } from "date-fns";
import { useGlobal } from "../../../context/global";

export default function Requirements() {
    const { setMessage } = useGlobal()
    const {setRequirementsTripId, requirementsTripId} = useAdmin()
    const [trip, setTrip] = useState<CommonTypes.TripReservation>()
    useEffect(() => {
        async function getTrip() {
            try {
                const data = (await AdminService.getTrip(requirementsTripId)).data
                setTrip(data)
            } catch (error) {
                console.log(error)
            }
        }
        getTrip()
    }, [requirementsTripId]) 
    if (!trip) return <></>
    const tripCosts = trip.costs && trip.costs.map(item => item.value).reduce((a,b) => a + b , 0)
    const passenger_passes = trip.reservations.map(item => item.pass_bought).filter(item => item !== false)
    const passenger_arrived = trip.reservations.map(item =>item.arrived_agency).filter(item => item !== false)
    const handleDownload = async () => {
        const newData: any[] = []
        const trip_date = trip.trip_date && format(new Date(trip.trip_date), 'YYY-MM-dd')
        for (let i = 0; i < trip.reservations.length; i++) {
            const reservation = trip.reservations[i];
            const passenger = objectToArray(reservation.passenger, ['name', 'email', 'age', 'nationality', 'phone', 'rut_passport', 'hotel', 'traspassed_from', 'remark'])
            const reservation_info = objectToArray(reservation, ['pass_bought', 'arrived_agency', 'price', 'tickets', 'traspass_price'])
            console.log(reservation_info)
            newData.push([trip.tour_id, ...passenger, ...reservation_info, reservation.agency.name, trip.agency.name, trip_date, trip.trip_time, trip.driver.name, trip.guider.name])
        }

        const data = [
            ['Tour', 'Name', 'Email', 'Age', 'Nationality', 'Phone', 'RUT / Passport', 'Hotel', 'Traspassed From', 'Remark', 'Pass Bought', 'Arrived Agency', 'Sale Price', 'Ticket Price', 'Traspass Price', 'Agency Owner', 'Agency Trip', 'Trip Date', 'Trip Depart', 'Driver', 'Guider'],
            ...newData
        ];
    
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
        XLSX.writeFile(wb, `${trip.tour_id}_${trip_date}_${trip.agency.name}.xlsx`);
      };

      async function closeTrip() {
        try {
            if (trip?.is_closed) return
            if (!tripCosts) return
            const response = await AdminService.closeTrip(requirementsTripId)
            setMessage({type: 'success', content: response.data})
            setTimeout(() => {
                setMessage(null)
            }, 2500)
            setRequirementsTripId(null)
        } catch (error) {
            console.log(error)
        }
      }
    return (
        <Main>
            <Modal>
                <Checkbox
                checked={trip?.reservations ? trip?.reservations.length >= trip?.max_capacity : false}
                label={`Group filled ${trip?.reservations.length}/${trip?.max_capacity}`}
                />
                <Checkbox
                checked={(passenger_passes.length === trip.reservations.length && passenger_passes.length > 0)}
                label={`Passengers passes ${passenger_passes.length}/${trip.reservations.length}`}
                />
                <Checkbox
                checked={(passenger_arrived.length === trip.reservations.length && passenger_passes.length > 0)}
                label={`Passengers arrived ${passenger_arrived.length}/${trip.reservations.length}`}
                />
                <Checkbox
                checked={tripCosts !== null || tripCosts === 0}
                label={`Trip costs $${tripCosts || 0}`}
                />
                <button type="submit"
                onClick={handleDownload}
                >Download list</button>
                {
                    (passenger_passes.length === trip.reservations.length)
                    &&
                    (passenger_arrived.length === trip.reservations.length)
                    &&
                    <button type="submit"
                    onClick={closeTrip}
                    >
                        {
                            trip.is_closed ?
                            'Trip closed'
                            :
                            'Close trip'
                        }
                    </button>
                }
                <div className="close" title="close"
                    onClick={() => {
                        setRequirementsTripId(null)
                    }}
                >
                    <AiOutlineCloseCircle/>
                </div>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
    `


const Modal = styled.div`
width: 80%;
max-height: 75%;
min-height: 400px;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%; 
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
padding-top: 50px;
animation: ${showUpModal} .3s ease forwards;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 20px;
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
button[type=submit] {
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

button[type=submit]::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

button[type=submit]:hover {
    color: ${props => props.theme.white};
}

button[type=submit]:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

button[type=submit]:hover::before {
    top: -30px;
    left: -30%;
}
`