import styled from "styled-components";
import { Traspass, AgencyTraspass, Payment } from "../../../types/common.types";
import { TbCirclesRelation } from "react-icons/tb";
import { useAdmin } from "../../../context/admin";
import formatNumberToString from "../../../utils/formatNumber";

export default function List({list, payments}: {list: Traspass[], payments: Payment[]}) {
    const {user} = useAdmin()
    const { setRelationshipAgencyId} = useAdmin()

    const transformed = list.reduce((acc: AgencyTraspass[], curr: Traspass) => {
        // Determine the amount to add to traspass_price based on balance
        const amount = curr.balance === 'positive' ? curr.traspass_price : -curr.traspass_price;
      
        // Find if there's already an entry for this agency in the accumulator
        const existingItem = acc.find(item => curr.balance === 'positive' ? curr.agency.id === item.agency.id : curr.agency_trip.id === item.agency.id );
    
        if (existingItem) {
            // If found, update the existing item's traspass_price
            existingItem.traspass_price += amount;
        } else {
            // If not found, create a new entry for the agency
            acc.push({
                agency: {
                    id: curr.balance === 'positive' ? curr.agency.id : curr.agency_trip.id,
                    email: curr.balance === 'positive' ? curr.agency.email : curr.agency_trip.email,
                    name: curr.balance === 'positive' ? curr.agency.name : curr.agency_trip.name
                },
                traspass_price: amount
            });
        }
      
        return acc;
    }, []);

    const transformedArray = transformed.map(obj => {
        const payments_array = payments.filter(payment => payment.owner === obj.agency.id || payment.agency_id === obj.agency.id)
        let amount = obj.traspass_price
        for (let i = 0; i < payments_array.length; i++) {
            const payment = payments_array[i];
            const type = payment.owner === user.user.agency_id ? 'send' : 'receive'
            if (type === 'receive') {
                amount = amount - payment.amount
            } else {
                amount = amount + payment.amount
            }
        }
        return {...obj, traspass_price: amount}
    })
    return (
        <Main>
            <TableWrapper>
                <Table>
                    <thead>
                        <tr>
                            <th>Agency</th>
                            <th>Traspass balance</th>
                            <th
                            style={{width: '60px'}}
                            ></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            transformedArray.map((item, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{item.agency.name}</td>
                                        <td>{formatNumberToString(item.traspass_price)}</td>
                                        <td>
                                            <button
                                            title="Ver relacion"
                                            onClick={() => setRelationshipAgencyId(item.agency.id)}
                                            ><TbCirclesRelation/></button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </TableWrapper>
        </Main>
    )
}

const Main = styled.div`
padding: 10px;
box-shadow: 0px 0 3px ${props => props.theme.blackTransparent};
border-radius: 10px;
`

const TableWrapper = styled.div`
width: 100%;
height: 100%;
overflow: hidden;
overflow-x: unset;
`

const Table = styled.table`
border-collapse: collapse;
table-layout: fixed;
height: 100%;
position: relative;
width: 100%;
min-width: 400px;
overflow: hidden;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 25px;
        height: 25px;
        border-radius: 5px;
        border: none;
        background-color: ${props => props.theme.blueLight};
        color: ${props => props.theme.white};
        cursor: pointer;
    }
}
tr {
    height: 40px;
    width: 100%;
}
`