import React, { useState } from 'react'
import styled from 'styled-components'
import { DefaultLogin } from '../../types/common.types'
import SellerService from '../../services/seller.service'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useSeller } from '../../context/seller'
import { useGlobal } from '../../context/global'

export default function Access() {
  const {setMessage} = useGlobal()
  const {setUser, user} = useSeller()
  const router = useNavigate()
  useEffect(() => {
    if (user) router('/seller')
}, [router, user])
  const [data, setData] = useState<DefaultLogin>(
    {
      email: '',
      password: ''
    }
  )
  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const name = e.target.name
    const value = e.target.value
    setData(prevData => ({...prevData, [name]: value}))
  }
  async function onSubmit(e: React.FormEvent) {
      e.preventDefault()
      try {
        const response = await SellerService.login(data)
        setMessage({
          type: 'success',
          content: 'Succesfully logged in'
        })
        setTimeout(() => {
          setMessage(null)
        }, 2500)
        setUser(response.data)
        response.data?.token && SellerService.setToken(response.data.token)
        localStorage.setItem('seller', JSON.stringify(response.data))
        router('/seller')
      } catch (error: any) {
        console.log(error.response)
        setMessage({
          type: 'error',
          content: error.response?.data
        })
        setTimeout(() => {
          setMessage(null)
        }, 2500)
      }
  }
    return (
        <Main>
            <div className="container">
                <div className="heading">
                  Sign In
                  <img src='/assets/logo.png' alt=''/>
                </div>
                <form className="form" onSubmit={onSubmit}>
                  <input required className="input" onChange={onChange} type="email" name="email" id="email" placeholder="E-mail"/>
                  <input required className="input" onChange={onChange} type="password" name="password" id="password" placeholder="Password"/>
                  <input className="login-button" type="submit" value="Sign In"/>
                </form>
            </div>
        </Main>
    )
}

const Main = styled.main`
width: 100vw;
height: 100vh;
background: linear-gradient(-45deg, ${props => props.theme.green} 0%, ${props => props.theme.lightGreen} 100%);
position: relative;
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 70%;
  max-width: 400px;
  background: #F8F9FD;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, ${props => props.theme.white} 100%);
  border-radius: 40px;
  padding: 25px 35px;
  border: 1px solid ${props => props.theme.green};
  box-shadow: 0 10px 15px rgba(0,0,0,0.2);
}

.heading {
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  color: ${props => props.theme.green};
  img {
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    width: 100px;
    filter: drop-shadow(0px 0 10px rgba(0,0,0,0.3));
  }
}

.form .input {
  width: 100%;
  background: white;
  border: none;
  padding: 15px 20px;
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: #cff0ff 0px 10px 10px -5px;
  border-inline: 2px solid transparent;
}

.form .input::-moz-placeholder {
  color: rgb(170, 170, 170);
}

.form .input::placeholder {
  color: rgb(170, 170, 170);
}

.form .input:focus {
  outline: none;
  border-inline: 2px solid ${props => props.theme.green}
}

.form .login-button {
    cursor: pointer;
    display: block;
    width: 100%;
    font-weight: bold;
    background: linear-gradient(45deg, ${props => props.theme.green} 0%, ${props => props.theme.lightGreen} 100%);
    color: white;
    padding-block: 15px;
    margin: 20px auto;
    border-radius: 20px;
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 20px 10px -15px;
    border: none;
    transition: all 0.2s ease-in-out;
}

.form .login-button:hover {
  transform: scale(1.03);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 23px 10px -20px;
}

.form .login-button:active {
  transform: scale(0.95);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 15px 10px -10px;
}
`