import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import AdminService from "../../../services/admin.service";
import { HealthSeller } from "../../../types/common.types";
import Sales from "./Sales";
import formatNumberToString from "../../../utils/formatNumber";

export default function SellerInfo({id, day, endDay}: {id: string, day: Date, endDay?: Date}) {
    const [data, setData] = useState<HealthSeller>({sales: []} as HealthSeller)
    useEffect(() => {
        async function getInfo() {
            try {
                if (!day) return setData({} as HealthSeller)
                const response = await AdminService.helthByDay(id, day, endDay)            
                setData({sales: response.data.sales})
            } catch (error) {
                console.log(error)
            }
        }
        getInfo()
    }, [id, day, endDay])
    const total_sales = data.sales?.map(sale => sale.reservation.price).filter(amount => amount).reduce((a: number,b: number) => a + b, 0) || 0

    const agency_earnings = data.sales?.map(sale => {
        const costs = ((sale.reservation.trip.costs?.map(obj => obj.value).reduce((a, b) => a + b, 0) || 0) / sale.reservation.trip.reservations) + (sale.reservation.cost || 0)
        const commission = sale.amount || ((sale.reservation.price * sale.seller.sell_commission) / 100)
        return (sale.reservation.traspass_price ? 
        sale.reservation.price - sale.reservation.traspass_price - commission  
        : sale.reservation.price - costs - commission - sale.reservation.tickets)
    })?.reduce((a,b) => a + b, 0)
    console.log(agency_earnings)

    const pending = useMemo(() => {
        let total = 0
        for (let i = 0; i < data.sales.length; i++) {
            const sale = data.sales[i];
            if (!sale.authorized) {
                const commission = sale.amount || (sale.reservation_price * (sale.seller.sell_commission / 100))
                total = total + commission
                continue;
            }
        }
        return total
    }, [data])
    const paid = useMemo(() => {
        let total = 0
        for (let i = 0; i < data.sales.length; i++) {
            const sale = data.sales[i];
            if (sale.authorized) {
                total = total + sale.amount
                continue;
            }
        }
        return total
    }, [data])
    return (
        <Main>
            <div className="accounting">
                <div>
                    pending 
                    <span>$ {formatNumberToString(pending)}</span>
                </div>
                <div>
                    pagado
                    <span>$ {formatNumberToString(paid)}</span>
                </div>
            </div>
            <div className="accounting">
                <div>
                    Comisión total
                    <span>$ {formatNumberToString(paid + pending)}</span>
                </div>
                <div>
                    Venta total
                    <span>$ {formatNumberToString(total_sales)}</span>
                </div>
                <div>
                    Ganancia agencia
                    <span>$ {formatNumberToString(agency_earnings || 0)}</span>
                </div>
            </div>
            <Sales
            sales={data.sales}
            setData={setData}
            />
        </Main>
    )
}

const Main = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 15px;
flex-direction: column;
margin-bottom: 20px;
.accounting {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 15px;
    div span {
        margin-left: 10px;
        background-color: ${props => props.theme.blue};
        color: ${props => props.theme.white};
        padding: 5px 10px;
    }
}
`