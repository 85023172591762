import styled from "styled-components";
import { mobile } from "../../../styles/responsive";
import { TripReservation } from "../../../types/common.types";
import { useAdmin } from "../../../context/admin";
import { FaTrash } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";
import add from "date-fns/add";
import { useGlobal } from "../../../context/global";
import AdminService from "../../../services/admin.service";
import removeTimezone from "../../../utils/removeTimezone";
import formatNumberToString from "../../../utils/formatNumber";

export default function Traspass({list}: {list: TripReservation[]}) {
    const {setMessage} = useGlobal()
    const { setCurrentDay, setEditReservationId, setPassengerPlanId} = useAdmin()
    
    async function deleteReservation(id: string) {
        try {
            await AdminService.deleteReservation(id)
            await setCurrentDay((prevDay: Date) => add(prevDay, {days: 1}))
            await setCurrentDay((prevDay: Date) => add(prevDay, {days: -1}))
            setMessage({
                type: 'success',
                content: 'Reservation successfully deleted'
            })
            setTimeout(() => setMessage(null), 2500)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Main>
            {
                list.map((trip, idx) => {
                    if (!trip.trip_date) return <></>
                    const agency_sale = trip.reservations.map(obj => obj.price).reduce((a,b) => a + b, 0)
                    const agency_commission = trip.reservations.map(obj => obj.sale?.amount || obj.sale ? (obj.price * obj.passenger.seller?.sell_commission / 100) : 0 || 0).reduce((a,b) => a + b, 0)
                    const traspass_cost = trip.reservations.map(obj => obj.traspass_price).reduce((a,b) => a + b, 0)
                    const date = removeTimezone(trip.trip_date.toString())

                    return (
                        <>
                        <div className="trip">
                            <div className="tour" key={idx}>
                            <div className="header">
                                <div className="name">
                                    <span>
                                        {trip.tour_id} {date}
                                    </span>
                                </div>
                            </div>
                            <TableWrapper>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{width: '40px'}}>
                                            </th>
                                            <th>Name</th>
                                            <th>Agency Trip</th>
                                            <th>Price</th>
                                            <th>Traspass Price</th>
                                            <th style={{width: '40px'}}
                                            ></th>
                                            <th style={{width: '40px'}}
                                            ></th>
                                            <th style={{width: '40px'}}
                                            ></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            trip.reservations?.map((reservation, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>
                                                            {idx + 1}
                                                        </td>
                                                        <td>{reservation.passenger.name}</td>
                                                        <td>{trip.agency.name}</td>
                                                        <td>{reservation.price}</td>
                                                        <td>{reservation.traspass_price}</td>
                                                        <td><button className="info" onClick={() => setPassengerPlanId(reservation.passenger_id)}><AiOutlineInfoCircle/></button></td>
                                                        <td><button className="info" onClick={() => setEditReservationId(reservation.id)}><MdEdit/></button></td>
                                                        <td><button className="info" onClick={() => deleteReservation(reservation.id)}><FaTrash/></button></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </TableWrapper>
                            </div>
                            <div className="accounting">
                                <div className="head">
                                    <span>Total venta: {formatNumberToString(agency_sale)}</span>
                                    <span>Comisiones: {formatNumberToString(agency_commission)}</span>
                                    <span>Costo traspaso: {formatNumberToString(traspass_cost)}</span>
                                </div>
                                <div className="total">
                                        AGENCIA GANANCIA: {formatNumberToString(agency_sale - agency_commission - traspass_cost)}
                                </div>
                            </div>
                        </div>
                        {
                            list.length - 1 !== idx &&
                            <hr />
                        }
                        </>
                    )
                })
            }
        </Main>
    )
}

const Main = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
width: 90%;
margin: auto;
margin-top: 15px;
${mobile({
    width: '100%'
})}
overflow: unset;
.trip {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.accounting {
    flex: 1;
    min-width: 300px;
    align-self: flex-end;
    .head {
        padding: 5px 10px;
        background-color: ${props => props.theme.blueDark};
        color: ${props => props.theme.white};
        > span {
            display: block;
        }
    }
    .total {
        text-align: center;
        color: ${props => props.theme.white};
        padding: 5px 10px;
        background-color: ${props => props.theme.greenDark};
    }
}
.tour {
    flex: 1;
    background-color: ${props => props.theme.whiteBackground};
    border-radius: 10px;
    box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
    .header {
        .name {
            flex: 1;
            text-align: center;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px 10px 0 0;
        position: relative;
        height: 40px;
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
    }
}
`

const TableWrapper = styled.div`
width: 100%;
overflow: auto;
min-height: 80px;
`

const Table = styled.table`
border-collapse: collapse;
position: relative;
table-layout: fixed;
height: 100%;
width: 100%;
min-width: 350px;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .flag {
        width: 30px;
    }
    button.select {
        width: 20px;
        height: 20px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
        }
    }
    button.selected {
        background-color: ${props => props.theme.yellowDark};
    }
    button.info {
        border: none;
        background-color: none;
        font-size: 16px;
        cursor: pointer;
    }
}
tr {
    height: 40px;
    width: 100%;
}
`