import styled from "styled-components";
import { useState, useRef, useEffect, CSSProperties } from "react";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai'

import {
    add,
    eachDayOfInterval,
    endOfMonth,
    format,
    getDay,
    isEqual,
    isBefore,
    parse,
    startOfToday,
    isSameMonth,
    isSameDay,
    isAfter
} from 'date-fns';

export interface CalendarInterface {
    selectedDay?: null | Date | number;
    endDay?: null | Date | number;
    setEndDay: Function;
    setSelectedDay: Function;
    calendarStyle?: CSSProperties;
    selectPastDays?: boolean;
    containerStyle?: CSSProperties;
    datesStyle?: Object
  }

export default function CalendarDual({
    endDay,
    setEndDay,
    selectedDay,
    setSelectedDay,
    calendarStyle,
    selectPastDays,
    containerStyle,
    datesStyle,
}: CalendarInterface) {
    const today = startOfToday();
    const [currentMonth, setCurrentMonth] = useState(format(selectedDay || today, 'MMM-yyyy'))
    const firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());
    const [currentMonthRight, setCurrentMonthRight] = useState(format(add(firstDayCurrentMonth, {months: 1}), 'MMM-yyyy'))
    const firstDayCurrentMonthRight = parse(currentMonthRight, 'MMM-yyyy', new Date());

    const days = eachDayOfInterval({
      start: firstDayCurrentMonth,
      end: endOfMonth(firstDayCurrentMonth),
    });
    const daysRight = eachDayOfInterval({
        start: firstDayCurrentMonthRight,
        end: endOfMonth(firstDayCurrentMonthRight),
      });
    const CalendarComponent = useRef<HTMLDivElement>(null);

    const firstDayPastMonth = add(firstDayCurrentMonth, { months: -1 });
    const firstDayPastMonthRight = add(firstDayCurrentMonthRight, { months: -1 });

    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    const firstDayNextMonthRight = add(firstDayCurrentMonthRight, { months: 1 });

    function previousMonth() {
        if (!selectPastDays && isSameMonth(firstDayCurrentMonth, today)) return;
        let firstDayPreviousMonth = add(firstDayCurrentMonth, { months: -1 });
        let firstDayPreviousMonthRight = add(firstDayCurrentMonthRight, { months: -1 });
        setCurrentMonth(format(firstDayPreviousMonth, 'MMM-yyyy'))
        setCurrentMonthRight(format(firstDayPreviousMonthRight, 'MMM-yyyy'))
    }
    
    function nextMonth() {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
        let firstDayNextMonthRight = add(firstDayCurrentMonthRight, { months: 1 });
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
        setCurrentMonthRight(format(firstDayNextMonthRight, 'MMM-yyyy'))
      }
    const pastMonthDays = eachDayOfInterval({
      start: firstDayPastMonth,
      end: endOfMonth(firstDayPastMonth),
    });
    const pastMonthDaysRight = eachDayOfInterval({
        start: firstDayPastMonthRight,
        end: endOfMonth(firstDayPastMonthRight),
      });

    const nextMonthDays = eachDayOfInterval({
      start: firstDayNextMonth,
      end: endOfMonth(firstDayNextMonth),
    });

    const nextMonthDaysRight = eachDayOfInterval({
      start: firstDayNextMonthRight,
      end: endOfMonth(firstDayNextMonthRight),
    });

    function handleSelectedDay(day: Date) {
        if (!selectPastDays && isBefore(day, today)) return;
        if (selectedDay && isSameDay(selectedDay, day)) {
            if (endDay) return setEndDay(undefined)
            else return setSelectedDay(null)
        }
        if (selectedDay && isBefore(day, selectedDay)) {
            return setSelectedDay(day)
        }
        if (selectedDay && isAfter(day, selectedDay)) {
            return setEndDay(day)
        }
        setSelectedDay(day);
    }
    return (
    <Main 
    ref={CalendarComponent}
    style={containerStyle}
    >
        <div className="calendar-wrapper" style={datesStyle}>
            <button onClick={previousMonth} className="previous">
                <AiOutlineArrowLeft/>
            </button>
            <Container
            style={calendarStyle}
            >
              <Header>
                <span>
                  {format(firstDayCurrentMonth, 'MMM')}
                  <span> </span>
                  {format(firstDayCurrentMonth, 'yyyy')}
                </span>
              </Header>
              <hr />
              <div className="daysweek">
                <div>Mon</div>
                <div>Tue</div>
                <div>Wen</div>
                <div>Thu</div>
                <div>Fri</div>
                <div>Sat</div>
                <div>Sun</div>
              </div>
              <div className="days">
                {pastMonthDays
                  .slice(
                    getDay(days[0]) !== 0 ? pastMonthDays.length - getDay(days[0]) + 1 : pastMonthDays.length - 6,
                    pastMonthDays.length,
                  )
                  .map((day, dayIdx) => {
                    let className = '';
                    if (selectedDay && isEqual(day, selectedDay)) className = 'selected'
                    if (endDay && isEqual(day, endDay)) className = 'selected'
                    return (
                      <div key={dayIdx} className={className}>
                        <button onClick={() => handleSelectedDay(day)} className="past">
                          <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                        </button>
                      </div>
                    );
                  })}
                {days.map((day, dayIdx) => {
                  let className = '';
                  if (selectedDay && isEqual(day, selectedDay)) className = 'selected'
                  if (endDay && isEqual(day, endDay)) className = 'selected'
                  return (
                    <div
                      key={dayIdx}
                      style={
                        dayIdx === 0
                          ? getDay(day) === 0
                            ? { gridColumn: '7/8' }
                            : { gridColumn: `${getDay(day)} / span 1` }
                          : {}
                      }
                      className={className}
                    >
                      <button onClick={() => handleSelectedDay(day)} className={!selectPastDays ? (isBefore(day, today) ? 'past' : '') : ''}>
                        <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                      </button>
                    </div>
                  );
                })}
                {nextMonthDays
                  .slice(0, getDay(days[days.length - 1]) ? 7 - getDay(days[days.length - 1]) : 0)
                  // if day of the week is 0 (sunday), then it will slide from 0 to 0, givin an empty array
                  // from first day 1 to the index of last day of the week within the month, then it substracs 7 to that given value
                  .map((day, dayIdx) => {
                    let className = '';
                    if (selectedDay && isEqual(selectedDay, day)) className = 'selected'
                    return (
                      <div key={dayIdx} className={className}>
                        <button onClick={() => handleSelectedDay(day)} className="next">
                          <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                        </button>
                      </div>
                    );
                  })}
              </div>
            </Container>
        </div>
        {/* right month */}
        <div className="calendar-wrapper" style={datesStyle}>
            <button onClick={nextMonth} className="next">
                <AiOutlineArrowRight/>
            </button>
            <Container
            style={calendarStyle}
            >
              <Header>
                <span>
                  {format(firstDayCurrentMonthRight, 'MMM')}
                  <span> </span>
                  {format(firstDayCurrentMonthRight, 'yyyy')}
                </span>
              </Header>
              <hr />
              <div className="daysweek">
                <div>Mon</div>
                <div>Tue</div>
                <div>Wen</div>
                <div>Thu</div>
                <div>Fri</div>
                <div>Sat</div>
                <div>Sun</div>
              </div>
              <div className="days">
                {pastMonthDaysRight
                  .slice(
                    getDay(daysRight[0]) !== 0 ? pastMonthDaysRight.length - getDay(daysRight[0]) + 1 : pastMonthDaysRight.length - 6,
                    pastMonthDaysRight.length,
                  )
                  .map((day, dayIdx) => {
                    let className = '';
                    if (endDay && isEqual(day, endDay)) className = 'selected'
                    return (
                      <div key={dayIdx} className={className}>
                        <button onClick={() => handleSelectedDay(day)} className="past">
                          <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                        </button>
                      </div>
                    );
                  })}
                {daysRight.map((day, dayIdx) => {
                  let className = '';
                  if (selectedDay && isEqual(day, selectedDay)) className = 'selected'
                  if (endDay && isEqual(day, endDay)) className = 'selected'
                  return (
                    <div
                      key={dayIdx}
                      style={
                        dayIdx === 0
                          ? getDay(day) === 0
                            ? { gridColumn: '7/8' }
                            : { gridColumn: `${getDay(day)} / span 1` }
                          : {}
                      }
                      className={className}
                    >
                      <button onClick={() => handleSelectedDay(day)} className={!selectPastDays ? (isBefore(day, today) ? 'past' : '') : ''}>
                        <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                      </button>
                    </div>
                  );
                })}
                {nextMonthDaysRight
                  .slice(0, getDay(daysRight[daysRight.length - 1]) ? 7 - getDay(daysRight[daysRight.length - 1]) : 0)
                  // if day of the week is 0 (sunday), then it will slide from 0 to 0, givin an empty array
                  // from first day 1 to the index of last day of the week within the month, then it substracs 7 to that given value
                  .map((day, dayIdx) => {
                    let className = '';
                    if (selectedDay && isEqual(selectedDay, day)) className = 'selected'
                    return (
                      <div key={dayIdx} className={className}>
                        <button onClick={() => handleSelectedDay(day)} className="next">
                          <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                        </button>
                      </div>
                    );
                  })}
              </div>
            </Container>
        </div>
    </Main>
    )
}

const Main = styled.div`
    position: relative;
    display: flex;
    background-color: ${props => props.theme.white};
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    .calendar {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      border: 2px solid ${props => props.theme.black};
      justify-content: center;
      font-size: 24px;
      cursor: pointer;
    }
  .calendar-wrapper {
    position: relative;
    top: calc(100% + 10px);
    padding: 0;
    left: 0;
    overflow: hidden;
    padding-bottom: 10px;
    border-radius: 16px;
    display: flex;
    align-items: flex-start;
    > button {
      top: 20px;
      position: absolute;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      width: 35px;
      height: 35px;
      cursor: pointer;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
    }
    > button.previous {
      left: 10px;
    }
    > button.next {
      right: 10px;
    }
  }
  div.date {
    padding: 10px 20px;
    height: 100%;
    cursor: pointer;
    border-radius: 176px;
    transition: background-color 0.03 ease;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      background-color: rgba(0, 0, 0, 0);
      border: none;
      margin-left: 10px;
      cursor: pointer;
    }
    :hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  div.date.selecting {
    background-color: ${props => props.theme.white};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

const Container = styled.div`
  background-color: ${props => props.theme.white};
  padding: 10px;
  width: 100%;
  .daysweek {
    width: 100%;
    margin: 0 auto;
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px 0;
    text-align: center;
    font-size: 14px;
  }
  .days {
    margin-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px 0;
    > div {
      margin: 0;
      font-size: 14px;
      font-family: 'Roboto';
      height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .next,
    .past {
      color: #a9a9a9;
    }
    button {
      width: 30px;
      height: 30px;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      cursor: pointer;
      border-radius: 50%;
      color: #000;
    }
    > div.selected {
      button {
        background: ${(props) => props.theme.blueDark};
        color: ${props => props.theme.white};
      }
    }
  }
`;

const Header = styled.div`
  padding: 20px 40px;
  text-align: center;
`;