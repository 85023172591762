import Header from "./Header";
import List from "./List";

export default function Agencies() {
    return (
        <>
        <Header/>
        <List/>
        </>
    )
}