import styled from "styled-components";
import { useSeller } from "../../../context/seller";
import { Sale } from "../../../types/common.types";
import { mobile } from "../../../styles/responsive";
import format from "date-fns/format";
import { useMemo, useState } from "react";
import { isSameMonth, isSameWeek, startOfToday, isWithinInterval, add, isToday } from "date-fns";
import formatNumberToString from "../../../utils/formatNumber";
import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

export default function Health() {
    const {sellerInfo} = useSeller()
    const [filter, setFilter] = useState<'all' | 'month' | 'week' | 'today'>('all')
    const [filterAuth, setFilterAuth] = useState<boolean | null>(null)
    const today = startOfToday()

    const month_accumulated = useMemo(() => {
        return sellerInfo.sales?.filter((sale: Sale) => {
            if (!sale.reservation.trip.trip_date) return false
            return isSameMonth(new Date(sale.reservation.trip.trip_date), new Date())
        })
        ?.map((sale: Sale) => {
            const commission = sale.amount || ((sale.reservation.price * sellerInfo.sell_commission) / 100)
            return commission
        })?.reduce((a: number, b: number) => a + b, 0) || 0
    }, [sellerInfo.sales])
    const month_goal = 960000
    const month_percent = (month_accumulated * 100 / month_goal)

    const week_accumulated = useMemo(() => {
        return sellerInfo.sales?.filter((sale: Sale) => {
            if (!sale.reservation.trip.trip_date) return false
            return isSameWeek(new Date(sale.reservation.trip.trip_date), new Date())
        })
        ?.map((sale: Sale) => {
            const commission = sale.amount || ((sale.reservation.price * sellerInfo.sell_commission) / 100)
            return commission
        })?.reduce((a: number, b: number) => a + b, 0) || 0
    }, [sellerInfo.sales])
    const week_goal = 240000
    const week_percent = (week_accumulated * 100 / week_goal)


    const today_accumulated = useMemo(() => {
        return sellerInfo.sales?.filter((sale: Sale) => {
            if (!sale.reservation.trip.trip_date) return false
            return isToday(new Date(sale.reservation.trip.trip_date))
        })
        ?.map((sale: Sale) => {
            const commission = sale.amount || ((sale.reservation.price * sellerInfo.sell_commission) / 100)
            return commission
        })?.reduce((a: number, b: number) => a + b, 0) || 0
    }, [sellerInfo.sales])
    const today_goal = 40000
    const today_percent = (today_accumulated * 100 / today_goal)
    return (
        <Main>
            <div className="accounting">
                <span
                className={filterAuth === false ? 'selected' : ''}
                onClick={() => {
                    if (filterAuth === false) return setFilterAuth(null)
                    setFilterAuth(false)
                }}
                >Pendiente ${formatNumberToString(sellerInfo.pending)}</span>
                <span
                className={filterAuth === true ? 'selected' : ''}
                onClick={() => {
                    if (filterAuth === true) return setFilterAuth(null)
                    setFilterAuth(true)
                }}
                >Autorizado ${formatNumberToString(sellerInfo.paid)}</span>
            </div>
            <h4>Este mes</h4>
            <Goal
            accumulated={`${month_percent > 100 ? 100 : month_percent}%`}
            >
                <div className="bar">
                </div>
                <span className="goal">{month_goal}</span>
                <span className="accumulated">{formatNumberToString(month_accumulated)}</span>
            </Goal>
            <h4>Esta semana</h4>
            <Goal
            accumulated={`${week_percent > 100 ? 100 : week_percent}%`}
            >
                <div className="bar">
                </div>
                <span className="goal">{week_goal}</span>
                <span className="accumulated">{formatNumberToString(week_accumulated)}</span>
            </Goal>
            <h4>Hoy</h4>
            <Goal
            accumulated={`${today_percent > 100 ? 100 : today_percent}%`}
            >
                <div className="bar">
                </div>
                <span className="goal">{today_goal}</span>
                <span className="accumulated">{formatNumberToString(today_accumulated)}</span>
            </Goal>
            <Filters>
                <button className={filter === 'all' ? 'selected': ''}
                onClick={() => setFilter('all')}
                >All</button>
                <button className={filter === 'month' ? 'selected' : ''}
                onClick={() => setFilter('month')}
                >Last month</button>
                <button className={filter === 'week' ? 'selected' : ''}
                onClick={() => setFilter('week')}
                >Last week</button>
                <button className={filter === 'today' ? 'selected' : ''}
                onClick={() => setFilter('today')}
                >Today</button>
            </Filters>
            <Sales>
                {sellerInfo.sales?.filter((sale: Sale) => {
                    const date = new Date(sale.reservation.created_at)
                    const tomorrow = add(today, {days: 1})
                    if (filter === 'all') return true
                    if (filter === 'month') {
                        const pastMonth = add(today, {months: -1})
                        return isWithinInterval(date, {start: pastMonth, end: tomorrow})
                    }
                    if (filter === 'week') {
                        const pastWeek = add(today, {weeks: -1})
                        return isWithinInterval(date, {start: pastWeek, end: tomorrow})
                    }
                    if (today) {
                        return isToday(date)
                    }
                    return true
                })
                .filter((sale: Sale) => {
                    if (filterAuth === null) return true
                    return sale.authorized === filterAuth
                })
                .map((sale: Sale, idx: number) => {
                    const commission = sale.amount || ((sale.reservation.price * sellerInfo.sell_commission) / 100)
                    const date = new Date(sale.reservation.created_at)
                    return (
                        <div className="tour" key={idx}>
                            <div className="header">
                                <div className="name">
                                    <span>
                                        {sale.reservation.tour_id}
                                    </span>
                                </div>
                            </div>
                            <TableWrapper> 
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Fecha de venta</th>
                                            <th>Precio de venta</th>
                                            <th>Forma de pago</th>
                                            <th>Tickets</th>
                                            <th>% comision</th>
                                            <th>comision</th>
                                            <th style={{width: '40px'}}>Auth</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{format(date, 'dd/MM/YYY')}</td>
                                            <td>{formatNumberToString(sale.reservation.price)}</td>
                                            <td>{sale.reservation.payment_methods.map((obj, idx) => ` ${idx > 0 ? '-' : ''} ${obj.name}`)}</td>
                                            <td>{sale.reservation.tickets}</td>
                                            <td>{sale.amount ? `${formatNumberToString((sale.amount * 100) / (sale.reservation.price))}%` : `${formatNumberToString(sellerInfo.sell_commission)}%`}</td>
                                            <td>{formatNumberToString(commission)}</td>
                                            <td>{sale.authorized ? <FaCheck/> : <IoClose/>}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </TableWrapper>
                        </div>
                    )
                })}
            </Sales>
        </Main>
    )
}

const Main = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
.accounting {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
    span {
        padding: 5px 10px;
        background-color: ${props => props.theme.gray};
        color: ${props => props.theme.white};
        cursor: pointer;
        user-select: none;
    }
    span.selected {
        background-color: ${props => props.theme.blue};
    }
}
h4 {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
}
`

const Sales = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
width: 90%;
margin: auto;
margin-top: 15px;
max-height: calc(65vh - 90px);
${mobile({
    width: '100%'
})}
overflow: unset;
.tour {
    background-color: ${props => props.theme.whiteBackground};
    border-radius: 10px;
    box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
    .header {
        .name {
            flex: 1;
            text-align: center;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px 10px 0 0;
        position: relative;
        height: 40px;
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
    }
}
`

const TableWrapper = styled.div`
width: 100%;
height: 100%;
overflow: hidden;
overflow-x: unset;
`

const Table = styled.table`
border-collapse: collapse;
position: relative;
table-layout: fixed;
height: 100%;
width: 100%;
min-width: 500px;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    > button {
        width: 20px;
        height: 20px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
            }
    }
    >button.selected-all {
        background-color: ${props => props.theme.yellowDark};
    }
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
    .flag {
        width: 30px;
    }
}
tr {
    height: 40px;
    width: 100%;
}
`

const Goal = styled.div<{accumulated: string}>`
width: 100%;
height: 20px;
background-color: ${props => props.theme.whiteBone};
max-width: 300px;
position: relative;

margin-bottom: 20px;
.bar {
    width: ${props => props.accumulated};
    height: 20px;
    background-color: ${props => props.theme.blueLight};
    position: absolute;
}
span {
    position: absolute;
    bottom: -15px;
    font-size: 12px;
}
span.accumulated {
    left: ${props => props.accumulated};
    background-color: ${props => props.theme.white};
}
span.goal {
    left: 100%;
}
`

const Filters = styled.div`
display: flex;
margin-top: 10px;
gap: 10px;
button {
    padding: 5px 10px;
    border: none;
    background-color: ${props => props.theme.gray};
    color: ${props => props.theme.white};
    font-size: 16px;
    cursor: pointer;
}
button.selected {
    background-color: ${props => props.theme.blueLight};
}
`