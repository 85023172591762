import Welcome from "./Welcome";
import List from "./List";

export default function Tours() {
    return (
        <>
        <Welcome/>
        <List/>
        </>
    )
}