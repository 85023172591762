import styled, { keyframes } from "styled-components";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import GeneralInput from "../../common/GeneralInput";
import { ChangeEvent, FormEvent, useState } from "react";
import * as CommonTypes from "../../../types/common.types";
import AdminService from "../../../services/admin.service";
import { useAdmin } from "../../../context/admin";


export default function CreateAgency() {
    const { setIsCreateAgencyModalOpen } = useAdmin()
    interface CreateAgency extends CommonTypes.Agency {
        password: string
    }
    const [data, setData] =  useState<Partial<CreateAgency>>({})
    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const name = e.target.name
        const value = e.target.value
        setData((prevData) => ({...prevData, [name]: value}))
    }

    async function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        console.log(data)
        try {
            await AdminService.createAgency(data)
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Main>
            <Modal onSubmit={onSubmit}>
                <h1>Create new agency</h1>
                <GeneralInput
                label="Name"
                value={data.name || ''}
                onChange={onChange}
                placeholder="Molantur"
                name="name"
                style={{marginBottom: '20px'}}
                />
                <GeneralInput
                label="Email"
                value={data.email || ''}
                onChange={onChange}
                placeholder="hola@molantur.com"
                name="email"
                style={{marginBottom: '20px'}}
                />
                <GeneralInput
                label="Password"
                value={data.password || ''}
                onChange={onChange}
                placeholder="123asd"
                name="password"
                style={{marginBottom: '20px'}}
                />
                <button type="submit">Create agency</button>
                <div className="close" title="close"
                onClick={() => setIsCreateAgencyModalOpen(false)}
                >
                    <AiOutlineCloseCircle/>
                </div>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
`

const Modal = styled.form`
width: 80%;
max-height: 75%;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
animation: ${showUpModal} .3s ease forwards;
h1 {
    font-size: 24px;
    margin-bottom: 20px;
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}

button {
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

button::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

button:hover {
    color: ${props => props.theme.white};
}

button:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

button:hover::before {
    top: -30px;
    left: -30%;
}
`