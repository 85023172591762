import styled from "styled-components";
import { useAdmin } from "../../../context/admin";

export default function AddNew() {
    const {setIsCreateAdminModalOpen} = useAdmin()
    return (
        <Button onClick={() => setIsCreateAdminModalOpen(true)}>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"></path></svg> 
                Create
            </span>
        </Button>
    )
}

const Button = styled.button`
margin: 20px 0;
border: 2px solid ${props => props.theme.yellowDark};
background-color: ${props => props.theme.yellowDark};
border-radius: 0.9em;
padding: 10px 20px 10px 12px;
transition: all ease-in-out 0.2s;
font-size: 14px;
cursor: pointer;
span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.white};
    font-weight: 600;
}
&:active {
    transform: scale(0.9);
}
`