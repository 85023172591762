import { StyledObject, css } from "styled-components"

export const mobile = (props: StyledObject<Object>)=>{
    return css`
    @media only screen and (max-width: 870px){
        ${props}
    }
    `
}

export const tiny = (props: StyledObject<Object>) => {
    return css`
    @media only screen and (max-width: 400px) {
        ${props}
    }
    `
}