import styled, {keyframes} from "styled-components";
import { useSeller } from "../../../context/seller";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { countries } from "countries-list";
import { useEffect, useState } from "react";
import * as CommonTypes from '../../../types/common.types'
import SellerService from "../../../services/seller.service";
import {format} from "date-fns";
import removeTimezone from "../../../utils/removeTimezone";

export default function TripInfo() {
    const {tripInfo, setTripInfo} = useSeller()
    const [plan, setPlan] = useState<CommonTypes.PassengerPlan[]>([])
    useEffect(() => {
        async function getPlan() {
            try {

                const response = (await SellerService.getPassengerPlan(tripInfo.passenger?.id)).data
                setPlan(response)
            } catch (error) {
                console.log(error)
            }
        }
        getPlan()
    }, [])
    console.log(plan)
    return (
        <Main>
            <Modal>
                <div className="close" title="close"
                onClick={() => setTripInfo(null)}
                >
                    <AiOutlineCloseCircle/>
                </div>
                <h2>Passenger plan</h2>
                {
                    plan.map((item, idx) => {
                        const date = removeTimezone(item.reservation_date.toString())
                        return (
                            <div key={idx} className="plan">
                                <div className="tour">
                                    <h1>{item.tour_id}</h1>
                                </div>
                                <div className="info">

                                <h2>Reservation</h2>
                                <h3>Reservation ID: <span>{item.reservation_id}</span></h3>
                                <h3>Reservation Date: <span>{date}</span></h3>
                                <h3>Tickets: <span>{item.tickets}</span></h3>
                                <h3>Price: <span>{item.price}</span></h3>

                                <h2>Agency: <span>{item.agency_name}</span></h2>
                                <h3>Agency Id: <span>{item.agency_id}</span></h3>
                                {
                                    item.trip_id &&
                                    <>
                                    <h2>Trip</h2>
                                    <h3>Trip ID:<span>{item.trip_id}</span></h3>
                                    <h3>Trip agency: <span>{item.trip_agency_name}</span></h3>
                                    <h3>Trip agency ID: <span>{item.trip_agency_id}</span></h3>
                                    <h3>Trip date: {item.trip_date &&removeTimezone(item.trip_date.toString())}</h3>
                                    </>
                                }
                                </div>
                            </div>
                        )
                    })
                }
                Reservation
                <br />
                ID: {tripInfo.id}
                <br />
                Reservation Date: {tripInfo.reservation_date}
                <br />
                <hr />
                Passenger
                <br />
                ID: {tripInfo.passenger?.id}
                <br />
                Name: {tripInfo.passenger?.name}
                <br />
                Email: {tripInfo.passenger?.email}
                <br />
                Phone: {tripInfo.passenger?.phone}
                <br />
                Nationality: {countries[tripInfo.passenger?.nationality as keyof typeof countries].name} 
                <br />
                Hotel: {tripInfo.passenger?.hotel} 
                <br />
                Rut / passport: {tripInfo.passenger?.rut_passport}
                <br />
                Remark: {tripInfo.passenger?.remark}
                <hr />
                {!tripInfo.trip ? 'Waiting for a trip...' : (
                    <>
                    Trip
                    <br />
                    ID: {tripInfo.trip?.id}
                    <br />
                    Max capacity: {tripInfo.trip?.max_capacity}
                    <br/>
                    Trip date: {tripInfo.trip?.trip_date}
                    <br />
                    Trip time: {tripInfo.trip?.trip_time}
                    <br />
                    Car patent: {tripInfo.trip?.car_patent}
                    <br />
                    Driver ID: {tripInfo.trip?.driver?.id}
                    <br />
                    Driver name: {tripInfo.trip?.driver?.name}
                    <br />
                    Guider ID: {tripInfo.trip?.guider?.id}
                    <br />
                    Guider name: {tripInfo.trip?.guider?.name}
                    <br />
                    </>
                )}
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
`

const Modal = styled.form`
width: 80%;
max-height: 75%;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
animation: ${showUpModal} .3s ease forwards;
> h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}
.plan {
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid ${props => props.theme.gray};
    .tour {
        background-color: ${props => props.theme.blue};
        border-radius: 10px 10px 0 0;
        color: ${props => props.theme.white};
        padding: 10px 0;
        h1 {
            text-align: center;
            font-size: 20px;
            margin: 0;
            font-weight: 600;
        }
    }
    .info {
        padding: 10px;
    }
    h2 {
        font-size: 18px;
        margin-top: 20px;
        font-weight: 500;
        margin: 5px 10px;
        &:first-child {
            margin: 0 10px;
            margin-bottom: 5px;
        }
    }
    h3 {
        font-size: 14px;
        margin: 0 10px;
        font-weight: 400;
    }
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
hr {
    border-top: 1px solid ${props => props.theme.blackTransparent};
    margin: 15px 0;
}
`