
function toQuery(obj: { [key: string]: any }): string {
    const searchParams = new URLSearchParams(); 

    for (const [key, value] of Object.entries(obj)) {
        searchParams.append(key, value);
    }

    return '?' + searchParams.toString();
}

export default toQuery;