import styled, { keyframes } from "styled-components";
import { useSeller } from "../../../context/seller";
import { useEffect, useState } from "react";
// components
import Calendar from "../../common/Calendar";
import InputDropdown from "../../common/InputDropdown";
// types
import * as CommonTypes from '../../../types/common.types'
// services
import ToursService from "../../../services/tours.service";
// icons
import { AiOutlineCloseCircle } from 'react-icons/ai';


export default function AddReservation () {
    const [tours, setTours] = useState<CommonTypes.Tour[]>([])
    const {newReservationData, setNewReservationData, setIsTicketPaymentModalOpen} = useSeller()
    useEffect(() => {
        async function listTours() {
            try {
                const response = await (await ToursService.list()).data
                setTours(response)
            } catch (error) {
                console.log(error)
            }
        }
        listTours()
    }, [])

    async function onSubmit() {
        console.log(newReservationData)
        if (newReservationData?.reservation_date && newReservationData?.tour_id) {
            console.log(tours)
            if (!tours.some(tour => tour.id === newReservationData.tour_id)) return
            setIsTicketPaymentModalOpen(true)
        }
    }
    return (
        <Main>
            <Modal>
                <div className="close" title="close"
                    onClick={() => window.location.reload()}
                >
                    <AiOutlineCloseCircle/>
                </div>
                <h1>Create a reservation</h1>
                {
                    tours.length > 0 &&
                    <InputDropdown
                    options={tours}
                    keys={['id']}
                    value={newReservationData?.tour_id}
                    selector="id"
                    optionDefaultHeight={35}
                    onChange={(newValue: string) => setNewReservationData({...newReservationData, tour_id: newValue})}
                    />
                }
                <Calendar
                containerStyle={{marginTop: '20px'}}
                selectedDay={newReservationData?.reservation_date}
                setSelectedDay={(newDate: Date) => setNewReservationData({...newReservationData, reservation_date: newDate})}
                />
                <button 
                type="submit"
                onClick={onSubmit}
                >Continue to payment</button>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`
const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
`

const Modal = styled.div`
width: 80%;
max-height: 75%;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
animation: ${showUpModal} .3s ease forwards;
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
h1 {
    font-size: 24px;
    margin-bottom: 20px;
}
> button {
    margin-top: 20px;
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

> button::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

> button:hover {
    color: ${props => props.theme.white};
}

> button:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

> button:hover::before {
    top: -30px;
    left: -30%;
}
`