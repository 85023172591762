import styled from "styled-components";
import Header from "./Header";
import { useState } from "react";
import Info from './Info'
import { mobile } from "../../../styles/responsive";

export default function Details() {
    const [isInfoOpen, setIsInfoOpen] = useState<boolean | null>(null)
    return (
        <Main>
            <Header
            setIsInfoOpen={setIsInfoOpen}
            isInfoOpen={isInfoOpen}
            />
            <Info
            setIsInfoOpen={setIsInfoOpen}
            isInfoOpen={isInfoOpen}
            />
        </Main>
    )
}

const Main = styled.div`
${mobile({
    width: '90%'
})}
width: 80%;
padding: 20px;
max-width: 650px;
border-radius: 15px;
background-color: ${props => props.theme.white};
box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
`