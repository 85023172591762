function arrayItemCompare(array1: any[], array2: any[]): boolean {
    // Check if arrays have the same length
    if (array1.length !== array2.length) {
      return false;
    }
  
    // Sort the arrays
    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();
  
    // Compare each item in the sorted arrays
    for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
        return false; // Items are not equal
    }
    }

    // All items are equal
    return true;
}

export default arrayItemCompare;