const theme = {
    green: '#1FAF09',
    greenDark: '#008D00',
    lightGreen: '#9ABC1B',
    blue: '#33AADD',
    blueLight: '#00C2DF',
    blueDark: '#4C96DA',
    yellow: '#FDF66F',
    yellowDark: '#FFDF71',
    whiteBone: '#F9F6EE',
    dark: '#3d3d3d',
    black: '#242424',
    blackout: '#000000',
    white: '#FFFFFF',
    gray: '#808080',
    whiteBackground: '#f1f1f3',
    transparent: 'rgba(0,0,0,0)',
    blackTransparent: 'rgba(0,0,0,0.1)',
    lightGreenFade: 'rgba(154, 188, 27, 0.5)',
    red: '#ff5252',
    lightRed: '#FA7070'
}

export default theme

export type MyTheme = typeof theme;