import styled from "styled-components";
import avatar from '../../../assets/common/avatar.svg'
import { useSeller } from "../../../context/seller";

export default function Profile() {
    const {sellerInfo} = useSeller()
    return (
        <Main>
            <div className="pic">
                <img src={avatar} alt="" />
            </div>
            <div>
            <h2>{sellerInfo.name}</h2>
            <h4>{sellerInfo.agency_name}</h4>
            </div>
        </Main>
    )
}

const Main = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 10px;
h2 {
    font-size: 24px;
    font-weight: 500;
}
h4 {
    font-size: 14px;
    font-weight: 400;
}
.pic {
    width: 80px;
    height: 80px;
    background-color: ${props => props.theme.lightGreen};
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 5px 5px rgba(0,0,0,0.2);
    img {
        height: 100%;
    }
}
`