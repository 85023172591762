const removeTimezone = (date: string): string => {
  let dateSepIdx = date.indexOf('T')
  if (dateSepIdx === -1) dateSepIdx = date.length
  const dateSep = date.slice(0, dateSepIdx)
  const dateList = dateSep.split('-')
  const year = dateList[0]
  const month = dateList[1]
  const day = dateList[2]
  const response = `${day}/${month}/${year}`
  return response
};

export default removeTimezone;
