import styled from "styled-components";
import { usePassenger } from "../../../context/passenger";
import { mobile } from "../../../styles/responsive";
import { PassengerPlan } from "../../../types/common.types";
import removeTimezone from "../../../utils/removeTimezone";
import { format } from "date-fns";

export default function Plan() {
    const {user} = usePassenger()
    if (!user?.user.plan?.length) return <></>
    return (
    <Main>
        {
            user?.user.plan?.map((reservation: PassengerPlan, idx: number) => {
                return (
                    <div className="tour" key={idx}>
                        <div className="header">
                            <div className="name">
                                <span>
                                    {reservation.tour_id}
                                </span>
                                <span>
                                    {reservation.trip_date ? removeTimezone(reservation.trip_date?.toString() || reservation.reservation_date.toString()) : removeTimezone(reservation.reservation_date.toString())}
                                </span>
                                <span>
                                    {reservation.trip_time}
                                </span>
                            </div>
                        </div>
                        <TableWrapper>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Your Agency</th>
                                        <th>Agency Trip</th>
                                        <th>Price</th>
                                        <th>Ticket</th>
                                        <th>Cost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>{reservation.agency_name}</th>
                                        <th>{reservation.trip_agency_name}</th>
                                        <th>{reservation.price}</th>
                                        <th>{reservation.tickets}</th>
                                        <th>{reservation.cost}</th>
                                    </tr>
                                </tbody>
                            </Table>
                        </TableWrapper>
                    </div>
                )
            })
        }
    </Main>
    )
}

const Main = styled.div`
${mobile({
    width: '90%'
})}
width: 80%;
padding: 20px;
max-width: 650px;
border-radius: 15px;
background-color: ${props => props.theme.white};
box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 20px;
.tour {
    background-color: ${props => props.theme.whiteBackground};
    border-radius: 10px;
    box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
    .header {
        .name {
            flex: 1;
            text-align: center;
            display: flex;
            gap: 10px;
            justify-content: center;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px 10px 0 0;
        position: relative;
        height: 40px;
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
        
    }
}
`

const TableWrapper = styled.div`
width: 100%;
height: 100%;
overflow: hidden;
overflow-x: unset;
`

const Table = styled.table`
border-collapse: collapse;
table-layout: fixed;
height: 100%;
position: relative;
width: 100%;
min-width: 300px;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .flag {
        width: 30px;
    }
    > button {
        width: 20px;
        height: 20px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
        }
    }
    > button.selected {
        background-color: ${props => props.theme.blueLight};
    }
}
tr {
    height: 40px;
    width: 100%;
}
`