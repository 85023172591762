import * as CommonTypes from "../types/common.types";
import http from "./http-common";

class PassengerServiceClass {
    private token: string | undefined;
    private config: { headers: { Authorization: string | undefined } };
    private passenger: string = '/passenger'
    private tours: string = '/tours'
    private trips: string = '/trips'
    private guider: string = '/guider'
    private driver: string = '/driver'
    private reservation: string = '/reservation'
    constructor(token?: string) {
        this.token = token;
        this.config = { headers: { Authorization: `Bearer ${this.token}` } };
    }
    
    setToken(token: string) {
      this.token = token;
      this.config = { headers: { Authorization: `Bearer ${this.token}` } };
    }

    login(data: CommonTypes.PassengerLogin) {
        return http.post(this.passenger + '/login', data)
    }

    me() {
        return http.get(this.passenger + '/me', this.config)
    }

    editPassenger(data: CommonTypes.Passenger) {
        return http.put(this.passenger + '/edit', data, this.config)
    }
    
}

const PassengerService = new PassengerServiceClass()

export default PassengerService;