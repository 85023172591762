import styled, {keyframes} from "styled-components";
import { useAdmin } from "../../../context/admin";
import { FormEvent, useEffect, useState } from "react";
import AdminService from "../../../services/admin.service";
import { Trip, Guider, Driver } from "../../../types/common.types";
import { AiOutlineCloseCircle } from "react-icons/ai";
import GeneralInput from "../../common/GeneralInput";
import InputDropdown from "../../common/InputDropdown";
import Checkbox from "../../common/Checkbox";


export default function EditTrip() {
    const {editTripId, setEditTripId, setTourInfoId} = useAdmin()

    const [data, setData] = useState<Trip>({} as Trip)
    const [guiders, setGuiders] = useState<Guider[]>([])
    const [drivers, setDrivers] = useState<Driver[]>([])
    useEffect(() => {
        async function getInfo() {
            try {
                const response = (await AdminService.getTrip(editTripId)).data
                setData(response)
                const guidersResponse = (await AdminService.listGuiders()).data
                setGuiders(guidersResponse)
                const driversResponse = (await AdminService.listDrivers()).data
                setDrivers(driversResponse)
            } catch (error) {
                console.log(error)
            }
        }
        getInfo()
    }, [])

    async function submit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            const newData: Trip = {
                guider_id: data.guider_id,
                driver_id: data.driver_id,
                car_patent: data.car_patent,
                trip_time: data.trip_time,
                pickup: data.pickup,
                meeting_point: data.meeting_point,
                recommendations: data.recommendations
            } as Trip
            await AdminService.editTrip(editTripId, newData)
            setEditTripId(null)
            setTourInfoId(null)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Main>
            <Modal onSubmit={submit}>
                <h1>Edit trip</h1>
                <div className="close" title="close"
                onClick={() => setEditTripId(null)}
                >
                    <AiOutlineCloseCircle/>
                </div>
                {
                    guiders.length > 0 &&
                    <InputDropdown
                    style={{marginBottom: '20px'}}
                    options={guiders}
                    optionDefaultHeight={55}
                    label="Guider ID"
                    onChange={(newValue: string) => setData(prevData => ({...prevData, guider_id: newValue}))}
                    keys={['id', 'name']}
                    value={data.guider_id}
                    selector="id"
                    />
                }
                {
                    drivers.length > 0 &&
                    <InputDropdown
                    style={{marginBottom: '20px'}}
                    options={drivers}
                    label="Driver ID"
                    keys={['id', 'name']}
                    selector="id"
                    optionDefaultHeight={55}
                    value={data.driver_id}
                    onChange={(newValue: string) => setData(prevData => ({...prevData, driver_id: newValue}))}
                    />
                }
                <GeneralInput
                value={data.car_patent}
                onChange={e => setData(prevData => ({...prevData, car_patent: e.target.value}))}
                label="Car patent"
                placeholder="AA150BL"
                style={{marginBottom: '20px'}}
                />
                <input type="time"
                value={data.trip_time}
                required
                onChange={(e) => setData(prevData => ({...prevData, trip_time: e.target.value}))}
                />
                <Checkbox
                checked={data?.pickup || false}
                color="#33AADD"
                label="Pickup"
                containerStyle={{margin: '20px 0'}}
                onChange={(newValue: boolean) => setData(prevData => ({...prevData, pickup: newValue}))}
                />
                {
                    !data?.pickup &&
                    <GeneralInput
                    value={data.meeting_point || ''}
                    label="Meeting point"
                    style={{marginBottom: '20px'}}
                    onChange={e => setData(prevData => ({...prevData, meeting_point: e.target.value}))}
                    />
                }
                <GeneralInput
                value={data.recommendations || ''}
                onChange={(e) => setData(prevData => ({...prevData, recommendations: e.target.value}))}
                name="recommendations"
                label="Recommendations"
                style={{marginBottom: '20px'}}
                />
                <button className="submit" type="submit">
                    Edit
                </button>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
    `

const Modal = styled.form`
width: 80%;
max-height: 75%;
min-height: 400px;
overflow: hidden;
overflow-y: unset;
max-width: 700px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
padding-top: 50px;
animation: ${showUpModal} .3s ease forwards;
h1 {
    font-size: 20px;
    margin-bottom: 20px;
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
.submit {
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    display: inline-block;
    width: 6.5em;
    height: 2.6em;
    overflow: hidden;
    font-size: 17px;
    text-align: center;
    z-index: 1;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}
.submit::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: 170px;
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

.submit:hover {
    color: ${props => props.theme.white};
}

.submit:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

.submit:hover::before {
    top: -30px;
    left: -30px;
}
`
