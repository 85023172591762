import styled from "styled-components";
import { useAdmin } from "../../../context/admin";
import { Link } from "react-router-dom";
import toQuery from "../../../utils/toQuery";
import { BiSolidBusiness } from 'react-icons/bi'

export default function SidebarList() {
    const { tab } = useAdmin()
    return (
        <>
        <div className={`${tab === 'agencies' && 'selected'}`}>
            <Link to={`/admin${toQuery({tab: 'agencies'})}`}>
                <BiSolidBusiness/> Agencies
            </Link>
        </div>
        </>
    )
}