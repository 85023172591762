import React, { useContext, useState } from "react";
import { Message } from "../types/common.types";

const AppProvider = React.createContext(null as any)

export const AppContext = ({children}: {children: React.ReactNode}) => {
    const [message, setMessage] = useState<Message>()
    const values = {
        message, setMessage
    }
    return <AppProvider.Provider value={values}>{children}</AppProvider.Provider>;
}

export const useGlobal = () => {
    return useContext(AppProvider);
  };