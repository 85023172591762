import styled from "styled-components";
import { useSeller } from "../../../context/seller";

export default function Sidebar() {
    const {setSellerTab, sellerTab} = useSeller()
    return (
        <Main>
            <div>
                <h3
                onClick={() => setSellerTab('profile')}
                className={sellerTab === 'profile' ? 'active' : ''}
                >
                    Profile
                </h3>
                <h3
                onClick={() => setSellerTab('health')}
                className={sellerTab === 'health' ? 'active' : ''}
                >
                    Health
                </h3>
            </div>
        </Main>
    )
}

const Main = styled.aside`
> div {
    margin: 20px auto;
    width: fit-content;
    h3 {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 400;
        color: ${props => props.theme.gray};
        cursor: pointer;
        transition: color .2s ease;
        &.active {
            color: #000;
        }
    }
}
`