import styled from "styled-components";
import formatNumberToString from "../../../utils/formatNumber";

interface AccInterface {
    total_income: number
    total_commission: number
}

export default function Accounting({total_commission, total_income}: AccInterface) {
    
    return (
        <Main>
            <span>Total ingresos: ${formatNumberToString(total_income)}</span>
            <span>Total comision: ${formatNumberToString(total_commission)}</span>
            <span>Total ganancia agencia: ${formatNumberToString(total_income - total_commission)}</span>
        </Main>
    )
}

const Main = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
gap: 10px;
span {
    display: block;
    padding: 5px 10px;
    background-color: ${props => props.theme.yellowDark};
    color: ${props => props.theme.white};
}
`