import styled from "styled-components";
import { useState } from "react";
// icons
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
// utils
import { format, add } from "date-fns";
// context
import { useAdmin } from "../../../context/admin";
import Calendar from "../../common/Calendar";

export default function Date() {
    const { currentDay, setCurrentDay } = useAdmin()
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)
    function handleCurrentDay (action: 'forwards' | 'backwards') {
        const newDate = add(currentDay, {days: action === 'backwards' ? -1 : 1} )
        setCurrentDay(newDate)
    }
    return (
        <Main>
            <button
            onClick={() => handleCurrentDay('backwards')}
            >
                <IoIosArrowBack/>
            </button>
            <div className="date"
            >
                <button className="open-calendar"
                onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                ></button>
                {
                    currentDay && <span 
                    >{format(currentDay, 'dd/MM/YYY')}</span>
                }
                <div className={`calendar ${isCalendarOpen ? 'open' : ''}`}>
                    <Calendar
                    selectedDay={currentDay}
                    setSelectedDay={setCurrentDay}
                    selectPastDays
                    />
                </div>
            </div>
            <button
            onClick={() => handleCurrentDay('forwards')}
            >
                <IoIosArrowForward/>
            </button>
        </Main>
    )
}

const Main = styled.div`
width: 100%;
padding: 30px 0;
display: flex;
justify-content: space-around;
> .date {
    padding: 20px;
    width: 70%;
    max-width: 350px;
    text-align: center;
    height: 100%;
    position: relative;
    z-index: 10;
    > button.open-calendar {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: ${props => props.theme.blueLight};
        cursor: pointer;
        border: none;
    }
    > .calendar {
        display: none;
        width: 350px;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        &.open {
            display: initial;
        }
    }
    > span {
        color: #FFF;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }
}
> button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    border: none;
    background-color: ${props => props.theme.white};
    font-size: 16px;
    color: #000;
    box-shadow: 0 5px 3px ${props => props.theme.blackTransparent};
    transition: transform .3s ease;
    cursor: pointer;
    &:hover {
        transform: translateY(-3px);
    }
}
`