import styled from "styled-components";
import background from '../../assets/seller/background.jpeg'
import backdrop from '../../assets/seller/backdrop.png'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
import CurrentDay from "../../components/sellers/CurrentDay";
import { useSeller } from "../../context/seller";
import { add } from "date-fns";
import Menu from "../../components/sellers/Menu";
import Tours from "../../components/sellers/Tours";
import TripInfo from "../../components/sellers/modals/TripInfo";
import Passengers from "../../components/sellers/modals/Passengers";
import CreatePassenger from "../../components/sellers/modals/CreatePassenger";
import CreateReservation from "../../components/sellers/modals/CreateReservation";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toQuery from "../../utils/toQuery";
import AddReservation from "../../components/sellers/modals/AddReservation";
import TicketPayment from "../../components/sellers/modals/TicketPayment"
import Navbar from "../../components/sellers/Navbar";
import { useSearchParams } from "react-router-dom";
import EditReservation from "../../components/sellers/modals/EditReservation";

export default function Home() {
    const {
        user,
        currentDay, 
        setCurrentDay, 
        tripInfo, 
        isPassengersModalOpen, 
        createReservationTourId, 
        isCreatePassengerModalOpen,
        isTicketPaymentModalOpen,
        editReservationId,
        newPassenger,
    } = useSeller()
    const router = useNavigate()
    function handleCurrentDay (action: 'forwards' | 'backwards') {
        const newDate = add(currentDay, {days: action === 'backwards' ? -1 : 1} )
        setCurrentDay(newDate)
    }
    useEffect(() => {
        if (!user) return router('/seller/access')
        if (currentDay) {
            router(`/seller${toQuery({currentDay})}`)
        }
    }, [user, router, currentDay])
    const searchParams = useSearchParams()
    useEffect(() => {
        const queryDay = searchParams[0].get('currentDay')
        const queryDate =  queryDay ? new Date(queryDay) : null
        if (!queryDate) return
        setCurrentDay(queryDate)
    }, [])
    return (
        <Main>
            <Navbar/>
            <Background>
                <img className="background" src={background} alt=""/>
                <img className="backdrop" src={backdrop} alt=""/>
            </Background>
            <Modal>
                <button className="back"
                onClick={() => handleCurrentDay('backwards')}
                >
                    <IoIosArrowBack/>
                </button>
                <button className="forw"
                onClick={() => handleCurrentDay('forwards')}
                >
                    <IoIosArrowForward/>
                </button>
                <CurrentDay/>
                <Menu/>
                <Tours/>
            </Modal>
            {
                tripInfo &&
                <TripInfo/>
            }
            {
                isPassengersModalOpen &&
                <Passengers />
            }
            {
                isCreatePassengerModalOpen &&
                <CreatePassenger />
            }
            {
                createReservationTourId &&
                <CreateReservation />
            }
            {
                newPassenger &&
                <AddReservation/>
            }
            {
                isTicketPaymentModalOpen &&
                <TicketPayment/>
            }
            {
                editReservationId &&
                <EditReservation/>
            }
        </Main>
    )
}

const Main = styled.div`
height: 100vh;
width: 100vw;
position: relative;
`

const Background = styled.div`
position: absolute;
height: 100%;
width: 100%;
background-color: ${props => props.theme.whiteBackground};
> img {
    &.background {
        height: 30vh;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
    }
    &.backdrop {
        top: 17vh;
        position: absolute;
        height: 83vh;
        width: 100%;
    }
 }
`

const Modal = styled.div`
height: 70%;
width: 85%;
background-color: ${props => props.theme.white};
border-radius: 10px;
max-width: 900px;
position: absolute;
top: 60%;
left: 50%;
transform: translate(-50%, -50%);
box-shadow: 0 10px 10px ${props => props.theme.blackTransparent};
> button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    border: none;
    background-color: ${props => props.theme.white};
    font-size: 16px;
    position: absolute;
    top: -40px;
    color: #000;
    box-shadow: 0 5px 3px ${props => props.theme.blackTransparent};
    transition: transform .3s ease;
    cursor: pointer;
    &:hover {
        transform: translateY(-3px);
    }
    &.back {
        left: 0;
    }
    &.forw {
        right: 0;
    }
}
`