import { CSSProperties, ChangeEvent } from "react";
import styled from "styled-components";

interface GeneralInputInterface {
    value?: string | number,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => any,
    image?: any,
    style?: CSSProperties,
    inputStyle?: CSSProperties,
    type?: string
    placeholder?: string
    label?: string
    name?: string
    min?: number
    max?: number
    required?: boolean,
    disabled?: boolean
}


export default function GeneralInput({
    value,
    onChange,
    image,
    placeholder,
    type,
    style,
    label,
    name,
    min,
    max,
    required,
    inputStyle,
    disabled
}: GeneralInputInterface) {
    return (
        <Main 
        disabled={disabled}
        style={style}>
            {
                label &&
                <label>{label}</label>
            }
            <div className="input">
            {
                image &&
                <img src={image} alt=""/>
            }
            <input 
            name={name} 
            placeholder={placeholder} 
            type={type || 'text'} 
            value={value} 
            min={min}
            style={inputStyle}
            max={max}
            readOnly={disabled}
            required={required}
            onChange={onChange}/>
            </div>
        </Main>
    )
}

const Main = styled.div<{disabled?: boolean}>`
display: flex;
flex-direction: column;
gap: 10px;
label {
    font-size: 16px;
}
.input {
    border-radius: 8px;
    border: 1px solid ${props => props.theme.gray};
    background-color: ${props => props.disabled ? props.theme.whiteBone : props.theme.white};
    height: 60px;
    padding: 10px 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    input {
        flex: 1;
        font-size: 16px;
        border: none;
        outline: none;
        background-color: ${props => props.disabled ? props.theme.whiteBone : props.theme.white};
        &::placeholder {
            color: ${props => props.theme.gray};
        }
    }
}
`