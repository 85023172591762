import styled, {keyframes} from "styled-components";
import { useAdmin } from "../../../context/admin";
import { FormEvent, useState } from "react";
import AdminService from "../../../services/admin.service";
import { EditTour as EditTourInterface } from "../../../types/common.types";
import { AiOutlineCloseCircle } from "react-icons/ai";
import GeneralInput from "../../common/GeneralInput";
import { useGlobal } from "../../../context/global";


export default function EditTour() {
    const {setMessage} = useGlobal()
    const {editTourData, setEditTourData} = useAdmin()
    const [data, setData] = useState<EditTourInterface>({
        id: editTourData.id,
        newId: editTourData.id
    })
    async function submit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            console.log(data)
            await AdminService.editTour(data)
            window.location.reload()
        } catch (error: any) {
            console.log(error)
            if (error.response?.data) {
                setMessage({
                    type: 'error',
                    content: error.response?.data
                })
                setTimeout(() => setMessage(null), 2500)
            }
        }
    }

    return (
        <Main>
            <Modal onSubmit={submit}>
                <h1>Edit tour</h1>
                <div className="close" title="close"
                onClick={() => setEditTourData(null)}
                >
                    <AiOutlineCloseCircle/>
                </div>
                <GeneralInput
                label="Name"
                value={data.newId || ''}
                onChange={e => setData(prevData => ({...prevData, [e.target.name]: e.target.value}))}
                placeholder="Molantur"
                name="newId"
                style={{marginBottom: '20px'}}
                />
                <button className="submit" type="submit">
                    Edit tour
                </button>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
    `

const Modal = styled.form`
width: 80%;
max-height: 75%;
overflow: hidden;
overflow-y: unset;
max-width: 700px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
animation: ${showUpModal} .3s ease forwards;
h1 {
    font-size: 20px;
    margin-bottom: 20px;
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
.submit {
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    display: inline-block;
    width: 6.5em;
    height: 2.6em;
    overflow: hidden;
    font-size: 17px;
    text-align: center;
    z-index: 1;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}
.submit::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: 170px;
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

.submit:hover {
    color: ${props => props.theme.white};
}

.submit:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

.submit:hover::before {
    top: -30px;
    left: -30px;
}
`
