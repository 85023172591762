import styled, { keyframes } from "styled-components";
import { useAdmin } from "../../../context/admin";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useEffect, useState, useMemo } from "react";
import AdminService from "../../../services/admin.service";
import { Payment, Traspass } from "../../../types/common.types";
import removeTimezone from "../../../utils/removeTimezone";

export default function AgencyRelationship() {
    const {relationshipAgencyId, setRelationshipAgencyId, user, setAddPaymentData, setViewPaymentAgencyId} = useAdmin()
    const [data, setData] = useState<Traspass[]>([])
    const [payments, setPayments] = useState<Payment[]>([])
    useEffect(() => {
        async function getInfo() {
            try {
                const response = await (await AdminService.getTraspass(relationshipAgencyId)).data
                setData(response.reservations)
                setPayments(response.payments)
            } catch (error) {
                console.log(error)
            }
        }
        getInfo()
    }, [relationshipAgencyId])
    const balance_positive = useMemo(() => {
        return data.map(item => item.balance === 'positive' ? item.traspass_price : 0).filter(item => item).reduce((a: number, b: number) => a + b, 0)
    }, [data])
    const balance_negative = useMemo(() => {
        return data.map(item => item.balance === 'negative' ? item.traspass_price : 0).filter(item => item).reduce((a: number, b: number) => a + b, 0)
    }, [data])
    const payments_positive = useMemo(() => {
        return payments.filter(payment => payment.owner !== user.user.agency_id).filter(item => item).map(payment => payment.amount).reduce((a: number, b: number) => a + b, 0)
    }, [payments, user])

    const payments_negative = useMemo(() => {
        return payments.filter(payment => payment.owner === user.user.agency_id).filter(item => item).map(payment => payment.amount).reduce((a: number, b: number) => a + b, 0)
    }, [payments, user])
    return (
        <Main>
            <Modal>
                <div className="close" title="close"
                    onClick={() => {
                        setRelationshipAgencyId(null)
                    }}
                >
                    <AiOutlineCloseCircle/>
                </div>
                <button className="view-payments"
                onClick={() => setViewPaymentAgencyId(data[0]?.agency.id !== user.user.agency_id ? data[0]?.agency.id : data[0]?.agency_trip.id)}
                >Ver pagos</button>
                <div className="header">
                    <span>
                        {data[0]?.agency.id === user.user.agency_id ? data[0]?.agency.name : data[0]?.agency_trip.name}
                        <button className="negative"
                        onClick={() => {
                            const agency_id = data[0]?.agency.id !== user.user.agency_id ? data[0]?.agency.id : data[0]?.agency_trip.id
                            setAddPaymentData({
                                agency_id: agency_id,
                                type: 'receive'
                            })
                        }}
                        >$ {payments_positive}</button>
                    </span>
                    <span>
                        {data[0]?.agency.id !== user.user.agency_id ? data[0]?.agency.name : data[0]?.agency_trip.name}
                        <button className="positive"
                        onClick={() => {
                            const agency_id = data[0]?.agency.id !== user.user.agency_id ? data[0]?.agency.id : data[0]?.agency_trip.id
                            setAddPaymentData({
                                agency_id: agency_id,
                                type: 'send'
                            })
                        }}
                        >$ {payments_negative}</button>
                    </span>
                </div>
                <div className="account">
                    <span>Balance</span>
                    <div>
                        <span className="positive">$ {balance_positive}</span>
                        <span className="negative">$ {balance_negative}</span>
                    </div>
                    <span className="general">$ {balance_positive - balance_negative}</span>
                </div>
                <h3>Positive</h3>
                {
                    data
                    .filter(reservation => reservation.balance === 'positive')
                    .map((reservation, idx) => {
                        return (
                            <div className="reservation" key={idx}>
                                <div className="header">
                                    <div className="name">
                                        <span>
                                            {reservation.balance === 'positive' ? reservation.agency.name : reservation.agency_trip.name}
                                        </span>
                                    </div>
                                </div>
                                <TableWrapper >
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Passenger</th>
                                                <th>Tour</th>
                                                <th>Traspass balance</th>
                                                <th>Tickets</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{removeTimezone(reservation.trip.trip_date?.toLocaleString() || reservation.reservation_date.toString())}</td>
                                                <td>{reservation.passenger.name}</td>
                                                <td>{reservation.tour_id}</td>
                                                <td>{reservation.balance === 'positive' ? reservation.traspass_price : `-${reservation.traspass_price}`}</td>
                                                <td>{reservation.tickets}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </TableWrapper>
                            </div>
                        )
                    })
                }
                <h3>Negative</h3>
                {
                    data
                    .filter(reservation => reservation.balance === 'negative')
                    .map((reservation, idx) => {
                        return (
                            <div className="reservation" key={idx}>
                                <div className="header">
                                    <div className="name">
                                        <span>
                                            {reservation.balance === 'positive' ? reservation.agency.name : reservation.agency_trip.name}
                                        </span>
                                    </div>
                                </div>
                                <TableWrapper >
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Passenger</th>
                                                <th>Tour</th>
                                                <th>Traspass balance</th>
                                                <th>Tickets</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{removeTimezone(reservation.trip.trip_date?.toLocaleString() || reservation.reservation_date.toString())}</td>
                                                <td>{reservation.passenger.name}</td>
                                                <td>{reservation.tour_id}</td>
                                                <td>{reservation.balance === 'positive' ? reservation.traspass_price : `-${reservation.traspass_price}`}</td>
                                                <td>{reservation.tickets}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </TableWrapper>
                            </div>
                        )
                    })
                }
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
    `

const Modal = styled.div`
width: 80%;
max-height: 75%;
min-height: 400px;
overflow: hidden;
overflow-y: unset;
max-width: 700px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
padding-top: 50px;
animation: ${showUpModal} .3s ease forwards;
.view-payments {
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    padding: 10px 20px;
    margin-bottom: 20px;
}
h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
> .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 250px;
    margin: 0 auto;
    border: 1px solid ${props => props.theme.dark};
    border-radius: 5px;
    > span {
        padding: 5px 10px;
        flex: 1;
        text-align: center;
        display: block;
        color: ${props => props.theme.dark};
        button {
            display: block;
            margin: auto;
            padding: 5px 10px;
            border: none;
            cursor: pointer;
            color: ${props => props.theme.white};
        }
        button.positive {
            background-color: ${props => props.theme.lightGreen};
        }
        button.negative {
            background-color: ${props => props.theme.lightRed};
        }
    }
}

.account {
    margin: 20px 0;
    > span {
        padding: 5px 10px;
        flex: 1;
        text-align: center;
        display: block;
        color: ${props => props.theme.white};
        background-color: ${props => props.theme.blueLight};
        width: 100px;
        margin: auto;
    }
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        max-width: 250px;
        margin: 0 auto;
        border: 1px solid ${props => props.theme.dark};
        border-radius: 5px;
        > span {
            padding: 5px 10px;
            flex: 1;
            text-align: center;
            display: block;
            color: ${props => props.theme.white};
            &.general {
                background-color: ${props => props.theme.blueLight};
            }
            &.positive {
                background-color: ${props => props.theme.lightGreen};
            }
            &.negative {
                background-color: ${props => props.theme.red};
            }
        }
    }
}

.reservation {
    background-color: ${props => props.theme.whiteBackground};
    border-radius: 10px;
    box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
    margin-bottom: 20px;
    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px 10px 0 0;
        position: relative;
        height: 40px;
        gap: 10px;
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
        .name {
            flex: 1;
            text-align: center;
            display: flex;
            gap: 10px;
            justify-content: center;
        }
    }
}
`
const TableWrapper = styled.div`
width: 100%;
height: 100%;
overflow: hidden;
overflow-x: unset;
`

const Table = styled.table`
border-collapse: collapse;
table-layout: fixed;
height: 100%;
position: relative;
width: 100%;
min-width: 400px;
overflow: hidden;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 25px;
        height: 25px;
        border-radius: 5px;
        border: none;
        background-color: ${props => props.theme.blueLight};
        color: ${props => props.theme.white};
        cursor: pointer;
    }
}
tr {
    height: 40px;
    width: 100%;
}
`