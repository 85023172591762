import styled from "styled-components";
import { countries, TCountryCode } from "countries-list";
import { ChangeEvent } from "react";

interface CountryInputInterface {
    value?: TCountryCode,
    name?: string,
    onChange?: (e: ChangeEvent<HTMLSelectElement & HTMLInputElement>) => any,
    style?: Object,
    label?: string
}

export default function CountryInput({value, onChange, name, style, label}: CountryInputInterface) {
    const defaultCountry = 'CL'
    return (
        <Main>
            {
                label &&
                <label>{label}</label>
            }
            <Dropdown style={style}>
                <select name={name} defaultValue={defaultCountry} onChange={onChange}>
                  {Object.keys(countries).map((country, index) => {
                      return (
                          <option key={index} value={country}>
                        {countries[country as keyof typeof countries].name}
                      </option>
                    );
                })}
                </select>
                <img width={40} height={20} src={`/flags_3x2/${value || defaultCountry}.svg`} alt="" />
            </Dropdown>
        </Main>
    )
}

const Main = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: fit-content;
gap: 5px;
`

const Dropdown = styled.div`
  padding: 5px;
  position: relative;
  /* margin-left: 5px; */
  select {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  img {
    height: 30px;
    padding: 5px;
    background: #f7f7f7;
  }
`;