import React, {useContext, useState} from "react";
import { GlobalUser } from "../types/common.types";
import SellerService from "../services/seller.service";
import { startOfToday } from 'date-fns'
import * as CommonTypes from '../types/common.types'

const SellerProvider = React.createContext(null as any)

export const SellerContext = ({children}: {children: React.ReactNode}) => {
    const getUser = () => {
        const user = localStorage.getItem('seller')
        if (user) {
            return JSON.parse(user)
        } else return null
    }
    // useStates
    const [user, setUser] = useState<GlobalUser>(getUser())
    const [tripInfo, setTripInfo] = useState()
    const [isPassengersModalOpen, setIsPassengersModalOpen] = useState(false)
    const [isCreatePassengerModalOpen, setIsCreatePassengerModalOpen] = useState(false)
    const [createReservationTourId, setCreateReservationTourId] = useState()
    const [newPassenger, setNewPassenger] = useState<Partial<CommonTypes.Passenger>>()
    const [newReservationData, setNewReservationData] = useState<Partial<CommonTypes.Reservation>>()
    const [isTicketPaymentModalOpen, setIsTicketPaymentModalOpen] = useState<boolean>(false)
    const [sellerInfo, setSellerInfo] = useState({})
    const [sellerTab, setSellerTab] = useState('profile')
    const [editReservationId, setEditReservationId] = useState<string>()
    
    const [currentDay, setCurrentDay] = useState<Date>(startOfToday())

    user?.token && SellerService.setToken(user.token)

    const values = {
        user, setUser,
        currentDay, setCurrentDay,
        tripInfo, setTripInfo,
        isPassengersModalOpen, setIsPassengersModalOpen,
        isCreatePassengerModalOpen, setIsCreatePassengerModalOpen,
        createReservationTourId, setCreateReservationTourId,
        newPassenger, setNewPassenger,
        newReservationData, setNewReservationData,
        isTicketPaymentModalOpen, setIsTicketPaymentModalOpen,
        sellerInfo, setSellerInfo,
        sellerTab, setSellerTab,
        editReservationId, setEditReservationId
    }
    return <SellerProvider.Provider value={values}>{children}</SellerProvider.Provider>;
}

export const useSeller = () => {
    return useContext(SellerProvider)
}