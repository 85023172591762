import styled from "styled-components";
import { useState, useRef, useEffect, CSSProperties } from "react";
import { AiOutlineCalendar, AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai'

import {
    add,
    eachDayOfInterval,
    endOfMonth,
    format,
    getDay,
    isEqual,
    isBefore,
    parse,
    startOfToday,
    isSameMonth,
    isSameDay
} from 'date-fns';

export interface CalendarInterface {
    selectedDay?: null | Date | number;
    setSelectedDay: Function;
    calendarStyle?: CSSProperties;
    selectPastDays?: boolean;
    containerStyle?: CSSProperties;
    datesStyle?: CSSProperties
  }

export default function Calendar({
    selectedDay,
    setSelectedDay,
    calendarStyle,
    selectPastDays,
    containerStyle,
    datesStyle,
}: CalendarInterface) {
    const today = startOfToday();
    const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
    const firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const days = eachDayOfInterval({
      start: firstDayCurrentMonth,
      end: endOfMonth(firstDayCurrentMonth),
    });
    const CalendarComponent = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
          if (CalendarComponent.current && !CalendarComponent.current?.contains(event.target)) {
            setIsCalendarOpen(false);
          }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [CalendarComponent]);

    const firstDayPastMonth = add(firstDayCurrentMonth, { months: -1 });

    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });

    function previousMonth() {
        if (!selectPastDays && isSameMonth(firstDayCurrentMonth, today)) return;
        let firstDayPreviousMonth = add(firstDayCurrentMonth, { months: -1 });
        setCurrentMonth(format(firstDayPreviousMonth, 'MMM-yyyy'))
      }
    
      function nextMonth() {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
      }
    const pastMonthDays = eachDayOfInterval({
      start: firstDayPastMonth,
      end: endOfMonth(firstDayPastMonth),
    });

    const nextMonthDays = eachDayOfInterval({
      start: firstDayNextMonth,
      end: endOfMonth(firstDayNextMonth),
    });

    function handleSelectedDay(day: Date) {
        if (!selectPastDays && isBefore(day, today)) return;
        if (selectedDay && isSameDay(selectedDay, day)) {
            return setSelectedDay(null)
        }
        setSelectedDay(day);
        setIsCalendarOpen(false)
    }
    return (
    <Main 
    ref={CalendarComponent}
    style={containerStyle}
    >
        <div
        onClick={() => {
          if (isCalendarOpen) {
            return setIsCalendarOpen(false);
          }
          if (selectedDay) {
            return setIsCalendarOpen(true);
          }
          setIsCalendarOpen(!isCalendarOpen);
        }}
        className="calendar"
        style={calendarStyle}
      >
        <AiOutlineCalendar/>
      </div>
      {
        isCalendarOpen &&
        <div className="calendar-wrapper" style={datesStyle}>
            <button onClick={previousMonth} className="previous">
                <AiOutlineArrowLeft/>
            </button>
            <button onClick={nextMonth} className="next">
                <AiOutlineArrowRight/>
            </button>
            <Container>
              <Header>
                <span>
                  {format(firstDayCurrentMonth, 'MMM')}
                  <span> </span>
                  {format(firstDayCurrentMonth, 'yyyy')}
                </span>
              </Header>
              <hr />
              <div className="daysweek">
                <div>Mon</div>
                <div>Tue</div>
                <div>Wen</div>
                <div>Thu</div>
                <div>Fri</div>
                <div>Sat</div>
                <div>Sun</div>
              </div>
              <div className="days">
                {pastMonthDays
                  .slice(
                    getDay(days[0]) !== 0 ? pastMonthDays.length - getDay(days[0]) + 1 : pastMonthDays.length - 6,
                    pastMonthDays.length,
                  )
                  .map((day, dayIdx) => {
                    let className = '';
                    if (selectedDay && isEqual(day, selectedDay)) className = 'selected'
                    return (
                      <div key={dayIdx} className={className}>
                        <button onClick={() => handleSelectedDay(day)} className="past">
                          <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                        </button>
                      </div>
                    );
                  })}
                {days.map((day, dayIdx) => {
                  let className = '';
                  if (selectedDay && isEqual(day, selectedDay)) className = 'selected'
                  return (
                    <div
                      key={dayIdx}
                      style={
                        dayIdx === 0
                          ? getDay(day) === 0
                            ? { gridColumn: '7/8' }
                            : { gridColumn: `${getDay(day)} / span 1` }
                          : {}
                      }
                      className={className}
                    >
                      <button onClick={() => handleSelectedDay(day)} className={!selectPastDays ? (isBefore(day, today) ? 'past' : '') : ''}>
                        <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                      </button>
                    </div>
                  );
                })}
                {nextMonthDays
                  .slice(0, getDay(days[days.length - 1]) ? 7 - getDay(days[days.length - 1]) : 0)
                  // if day of the week is 0 (sunday), then it will slide from 0 to 0, givin an empty array
                  // from first day 1 to the index of last day of the week within the month, then it substracs 7 to that given value
                  .map((day, dayIdx) => {
                    let className = '';
                    if (selectedDay && isEqual(selectedDay, day)) className = 'selected'
                    return (
                      <div key={dayIdx} className={className}>
                        <button onClick={() => handleSelectedDay(day)} className="next">
                          <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                        </button>
                      </div>
                    );
                  })}
              </div>
            </Container>
        </div>
      }
    </Main>
    )
}

const Main = styled.div`
    position: relative;
    z-index: 9;
    .calendar {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid ${props => props.theme.black};
      border-radius: 5px;
      font-size: 24px;
      cursor: pointer;
    }
  .calendar-wrapper {
    position: absolute;
    background-color: ${props => props.theme.white};
    top: calc(100% + 10px);
    padding: 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    left: 0;
    overflow: hidden;
    padding-bottom: 10px;
    border-radius: 16px;
    display: flex;
    align-items: flex-start;
    > button {
      top: 20px;
      position: absolute;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      width: 35px;
      height: 35px;
      cursor: pointer;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
    }
    > button.previous {
      left: 10px;
    }
    > button.next {
      right: 10px;
    }
  }
  div.date {
    padding: 10px 20px;
    height: 100%;
    cursor: pointer;
    border-radius: 176px;
    transition: background-color 0.03 ease;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      background-color: rgba(0, 0, 0, 0);
      border: none;
      margin-left: 10px;
      cursor: pointer;
    }
    :hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  div.date.selecting {
    background-color: ${props => props.theme.white};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

const Container = styled.div`
  background-color: ${props => props.theme.white};
  padding: 10px;
  .daysweek {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px 0;
    font-size: 14px;
    text-align: center;
  }
  .days {
    margin-top: 10px;
    width: 350px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px 0;
    > div {
      margin: 0;
      font-size: 14px;
      font-family: 'Roboto';
      height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .next,
    .past {
      color: #a9a9a9;
    }
    button {
      width: 30px;
      height: 30px;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      cursor: pointer;
      border-radius: 50%;
      color: #000;
    }
    > div.selected {
      button {
        background: ${(props) => props.theme.blueDark};
        color: ${props => props.theme.white};
      }
    }
  }
`;

const Header = styled.div`
  padding: 20px 40px;
  text-align: center;
`;