import React, {useContext, useState} from "react";
import { GlobalUser } from "../types/common.types";
import PassengerService from "../services/passenger.service";

const PassengerProvider = React.createContext(null as any)

export const PassengerContext = ({children}: {children: React.ReactNode}) => {
    const getUser = () => {
        const user = localStorage.getItem('passenger')
        if (user) {
            return JSON.parse(user)
        } else return null
    }
    // useStates
    const [user, setUser] = useState<GlobalUser>(getUser())
    
    // add token to class object
    user?.token && PassengerService.setToken(user.token)

    const values = {
        user, setUser,
        
    }
    return <PassengerProvider.Provider value={values}>{children}</PassengerProvider.Provider>;
}

export const usePassenger = () => {
    return useContext(PassengerProvider)
}