import styled from "styled-components";
import { useAdmin } from "../../../context/admin";

export default function Welcome() {
    const {user, setIsCreateTourModalOpen} = useAdmin()
    return (
        <Main>
            <h2>Welcome {user?.user?.name}!</h2>
            <div className="buttons">
                {
                    user?.user.source === 'superowners' &&
                    <button className="tour" onClick={() => setIsCreateTourModalOpen(true)}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"></path></svg> 
                            Create Tour
                        </span>
                    </button>
                }
            </div>
        </Main>
    )
}

const Main = styled.section`
width: 100%;
padding: 20px 0;
display: flex;
justify-content: space-between;
h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
}
.buttons {
    display: flex;
    gap: 15px;
}
button {
    border-radius: 0.9em;
    padding: 10px 20px 10px 12px;
    transition: all ease-in-out 0.2s;
    font-size: 14px;
    cursor: pointer;
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${props => props.theme.white};
        font-weight: 600;
    }
    &:active {
        transform: scale(0.9);
    }
}
.tour {
    border: 2px solid ${props => props.theme.lightGreen};
    background-color: ${props => props.theme.lightGreen};
}
.trip {
    border: 2px solid ${props => props.theme.yellowDark};
    background-color: ${props => props.theme.yellowDark};
}
`