import React, { useState, useRef, useEffect, useMemo, CSSProperties } from "react";
import styled from "styled-components";

interface DropdownProps {
  options: any[];
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  keys: string[];
  label?: string,
  onChange: Function,
  value?: string;
  selector: string;
  optionDefaultHeight?: number;
}

export default function InputDropdown ({ onChange, value, containerStyle, options, style, keys, label, optionDefaultHeight, selector }: DropdownProps) {
  const [filterText, setFilterText] = useState(value || "");
  const [filteredOptions, setFilteredOptions] = useState<any[]>(options);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const InputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (InputRef.current && !InputRef.current?.contains(event.target)) {
                setDropdownVisible(false)
            }
          }
          // Bind the event listener
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
          };
    }, [InputDropdown])
  const dropdownHeight = useMemo(() => {
    const numOptions = filteredOptions.length;
    const sum: number = numOptions === 2 ? 1 : numOptions === 1 ? 2 : 0
    const optionHeight = (optionDefaultHeight || 60)  + sum; // Set the height of each option here
    const calculatedHeight = numOptions * optionHeight;
    const finalHeight = calculatedHeight
     > 200 ? 200 : calculatedHeight;
    return isDropdownVisible ? finalHeight : 0
  }, [isDropdownVisible, filteredOptions]);

    useEffect(() => {
        onChange(filterText)
    }, [filterText])
    const handleFilterChange = (text: string) => {
    setFilterText(text);
    
    let filtered: any[] = []

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const filteredOption = options.filter((option) => 
            option[key] && option[key].toLowerCase().includes(text.toLowerCase())
        );
        for (let j = 0; j < filteredOption.length; j++) {
            const element = filteredOption[j];
            if (filtered.includes(element)) continue
            filtered.push(element)
        }
    }

    setFilteredOptions(filtered);
    setDropdownVisible(true);
    };

    const handleOptionSelect = (option: string) => {
        setFilterText(option);
        setDropdownVisible(false);
    };

    const renderItem = (item: any, idx: number) => {
        return (
        <OptionItem key={idx} onClick={() => handleOptionSelect(item[selector as any])}>
            {
                keys.map((key: string, idx: number) => {
                    return (
                    <React.Fragment key={idx}>
                        {item[key as any]}
                        <br />
                    </React.Fragment>
                    )
                })
            }
        </OptionItem>
        );
    };


return (
    <Main
    style={containerStyle}
    >
        {
            label &&
            <label>{label}</label>
        }
        <DropdownContainer 
        ref={InputRef}
        style={style}>
            <DropdownInput
            placeholder="Search..."
            value={filterText}
            onChange={(e) => handleFilterChange(e.target.value)}
            onFocus={() => setDropdownVisible(true)}
            />
            {isDropdownVisible && (
                <DropdownList style={{ height: `${dropdownHeight}px` }}>
                {filteredOptions.map((item, idx) => renderItem(item, idx))}
            </DropdownList>
            )}
        </DropdownContainer>
    </Main>
    );
};

const Main = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
`

const DropdownContainer = styled.div`
    position: relative;
    flex-direction: column;
    align-items: flex-start;
`;

const DropdownInput = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.gray};
    height: 60px;
    padding: 10px 20px;
    &::placeholder {
        color: ${props => props.theme.gray};
    }
    &:focus {
        border-radius: 8px 8px 0 0;
    }
`;

const DropdownList = styled.div`
    position: absolute;
    z-index: 10;
    top: 60px;
    width: 100%;
    max-height: 200px;
    border: 1px solid ${props => props.theme.gray};
    background-color: #fff;
    overflow: hidden;
    overflow-y: unset;
`;

const OptionItem = styled.div`
    padding: 8px 16px;
    cursor: pointer;
    &:hover {
        background-color: #f0f0f0;
    }
`;
