import styled, { keyframes } from "styled-components";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useSeller } from "../../../context/seller";
import { useEffect, useState, ChangeEvent } from "react";
import SellerService from "../../../services/seller.service";
import { Reservation, Tour, PaymentMethods } from "../../../types/common.types";
import InputDropdown from "../../common/InputDropdown";
import ToursService from "../../../services/tours.service";
import GeneralInput from "../../common/GeneralInput";
import Calendar from "../../common/CalendarInput";
import { format } from "date-fns";
import removeTimezone from "../../../utils/removeTimezone";
import { FaTrash } from "react-icons/fa";
import Dropdown from "../../common/Dropdown";
import insert from "../../../utils/insert";
import dollar from '../../../assets/common/dollar.svg'

export default function EditReservation() {
    const {setEditReservationId, editReservationId} = useSeller()
    const [data, setData] = useState<Reservation>({} as Reservation)
    const [tours, setTours] = useState<Tour[]>([])
    useEffect(() => {
        async function getInfo() {
            try {
                const response = (await SellerService.getReservation(editReservationId)).data
                setData(response)
            } catch (error) {
                console.log(error)
            }
            try {
                const response = await (await ToursService.list()).data
                setTours(response)
            } catch (error) {
                console.log(error)
            }
        }
        getInfo()
    }, [])
    function onSelectPayment(newMethod: string) {
        const new_data = data.payment_methods ? [...data.payment_methods, {name: newMethod}] : [{name: newMethod}]
        setData(prevData => ({...prevData, payment_methods: new_data}))
    }
    function onChangePayment(e: ChangeEvent<HTMLInputElement>) {
        const value = Number(e.target.value)
        const name = e.target.name
        if (!data.payment_methods) {
            const newList = [{name, value}]
            setData(prevData => ({...prevData, payment_methods: newList}))
            return
        }
        const oldItem = data.payment_methods.find((item: PaymentMethods) => item.name === name)
        if (!oldItem) return
        const index = data.payment_methods.indexOf(oldItem)
        console.log(index)
        const filteredList = [...data.payment_methods.filter((item: PaymentMethods) => item.name !== name)]
        const newData = {...oldItem, value}
        setData(prevData => ({...prevData, payment_methods: insert(filteredList, index, newData)}))
    }

    function deletePayment(name: string) {
        const newList = data.payment_methods.filter((item: PaymentMethods) => item.name !== name)
        setData(prevData => ({...prevData, payment_methods: newList}))
    } 

    async function onSubmit() {
        if (data?.reservation_date && data?.tour_id) {
            if (!tours.some(tour => tour.id === data.tour_id)) return
            const newData: Reservation = {
                tour_id: data.tour_id,
                reservation_date: data.reservation_date,
                tickets: data.tickets,
                price: data.price,
                payment_methods: data.payment_methods,
                cost: data.cost
            } as Reservation
            try {
                await SellerService.editReservation(editReservationId, newData)
                window.location.reload()
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <Main>
            <Modal>
                <div className="close" title="close"
                    onClick={() => setEditReservationId(null)}
                >
                    <AiOutlineCloseCircle/>
                </div>
                <h1>Create a reservation</h1>
                {
                    tours.length > 0 &&
                    <InputDropdown
                    options={tours}
                    keys={['id']}
                    value={data.tour_id}
                    selector="id"
                    optionDefaultHeight={35}
                    onChange={(newValue: string) => setData(prevData => ({...prevData, tour_id: newValue}))}
                    />
                }
                {
                    data.reservation_date &&
                    <div 
                    style={{margin: '20px 0'}}
                    className="calendar">
                        <Calendar
                        selectedDay={data.reservation_date}
                        setSelectedDay={(newDate: Date) => setData(prevData => ({...prevData, reservation_date: newDate}))}
                        />
                        {removeTimezone(data.reservation_date.toString())}
                    </div>
                }
                <GeneralInput
                label="Ticket price"
                type="number"
                value={data.tickets || ''}
                onChange={e => setData(prevData => ({...prevData, tickets: Number(e.target.value)}))}
                />
                <GeneralInput
                label="Sale price"
                type="number"
                style={{margin: '20px 0'}}
                value={data.price || ''}
                onChange={e => setData(prevData => ({...prevData, price: Number(e.target.value)}))}
                />
                <h3 className="payment-title">Payment methods</h3>
                {
                    data?.payment_methods?.map((method, idx: number) => {
                        return (
                            <div className="payment_method" key={idx}>
                                <button
                                onClick={() => deletePayment(method.name)}
                                >
                                    <FaTrash/>
                                </button>
                                <GeneralInput
                                value={method.value || ''}
                                name={method.name}
                                type="number"
                                placeholder="Amount"
                                label={method.name}
                                image={dollar}
                                onChange={onChangePayment}
                                />
                            </div>
                        )
                    })
                }
                <Dropdown
                style={{marginTop: '20px'}}
                options={['efectivo', 'wise', 'transferencia', 'tarjeta', 'deuda'].filter(str => !data?.payment_methods?.map(item => item.name).includes(str))}
                title="Payment methods"
                onSelect={onSelectPayment}
                />
                <GeneralInput
                label="Cost"
                type="number"
                value={data.cost || ''}
                onChange={e => setData(prevData => ({...prevData, cost: Number(e.target.value)}))}
                />
                <button 
                type="submit"
                onClick={onSubmit}
                >Submit</button>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`
const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
`

const Modal = styled.div`
width: 80%;
max-height: 75%;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
animation: ${showUpModal} .3s ease forwards;
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}
.payment_method {
    margin-bottom: 10px;
    position: relative;
    button {
        position: absolute;
        right: 0;
        top: 0;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        background-color: rgba(0,0,0,0);
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid ${props => props.theme.dark};
    }
    h4 {
        font-weight: 400;
        margin-bottom: 5px;
    }
    label {
        text-transform: capitalize;
    }
}
> button.back {
    background-color: rgba(0,0,0,0);
    border: none;
    font-size: 20px;
    cursor: pointer;
    transition: transform .3s ease;
    &:hover {
        transform: translateY(-3px);
    }
}
h3.payment-title {
    font-weight: 500;
    margin-bottom: 10px;
}
> .calendar {
    display: flex;
    gap: 10px;
    align-items: center;
}
> button {
    margin-top: 20px;
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

> button::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

> button:hover {
    color: ${props => props.theme.white};
}

> button:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

> button:hover::before {
    top: -30px;
    left: -30%;
}
`