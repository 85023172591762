import React, {useContext, useState} from "react";
import { GlobalUser } from "../types/common.types";
import AdminService from "../services/admin.service";
import { startOfToday } from 'date-fns'

const AdminProvider = React.createContext(null as any)

export const AdminContext = ({children}: {children: React.ReactNode}) => {
    const getUser = () => {
        const user = localStorage.getItem('admin')
        if (user) {
            return JSON.parse(user)
        } else return null
    }
    // useStates
    const [user, setUser] = useState<GlobalUser>(getUser())
    const [tab, setTab] = useState()
    const [isCreateTourModalOpen, setIsCreateTourModalOpen] = useState(false)
    const [tourInfoId, setTourInfoId] = useState<string>()
    const [isCreatePassengerModalOpen, setIsCreatePassengerModalOpen] = useState(false)
    const [isCreateAgencyModalOpen, setIsCreateAgencyModalOpen] = useState(false)
    const [isCreateSellerModalOpen, setIsCreateSellerModalOpen] = useState(false)
    const [isCreateAdminModalOpen, setIsCreateAdminModalOpen] = useState(false)
    const [isCreateDriverModalOpen, setIsCreateDriverModalOpen] = useState(false)
    const [isCreateGuiderModalOpen, setIsCreateGuiderModalOpen] = useState(false)
    const [passengerPlanId, setPassengerPlanId] = useState<string>()
    const [assignTripReservationId, setAssignTripReservationId] = useState<string[]>([])
    const [assignTripTourId, setAssignTripTourId] = useState()
    const [traspassTripReservationId, setTraspassTripReservationId] = useState<string[]>([])
    const [traspassTripTourId, setTraspassTripTourId] = useState()
    const [registerPassengersTripId, setRegisterPassengersTripId] = useState<string>()
    const [registerCostsTripId, setRegisterCostsTripId] = useState<string>()
    const [requirementsTripId, setRequirementsTripId] = useState<string>()
    const [accountingSellerId, setAccountingSellerId] = useState<string>()
    const [editTripId, setEditTripId] = useState<string>()
    const [relationshipAgencyId, setRelationshipAgencyId] = useState<string>()
    const [editReservationId, setEditReservationId] = useState<string>()
    const [editPassengerData, setEditPassengerData] = useState()
    const [editTourData, setEditTourData] = useState()
    const [editDriverData, setEditDriverData] = useState()
    const [editGuiderData, setEditGuiderData] = useState()
    const [editSellerData, setEditSellerData] = useState()
    const [editAdminData, setEditAdminData] = useState()
    const [editAgencyData, setEditAgencyData] = useState()
    const [addPaymentData, setAddPaymentData] = useState()
    const [reservationDetails, setReservationDetails] = useState()
    const [viewPaymentAgencyId, setViewPaymentAgencyId] = useState()

    const [currentDay, setCurrentDay] = useState<Date>(startOfToday())
    const [endDay, setEndDay] = useState<Date>()
    // add token to class object
    user?.token && AdminService.setToken(user.token)

    const values = {
        user, setUser,
        tab, setTab,
        isCreateTourModalOpen, setIsCreateTourModalOpen,
        tourInfoId, setTourInfoId,
        isCreatePassengerModalOpen, setIsCreatePassengerModalOpen,
        isCreateAgencyModalOpen, setIsCreateAgencyModalOpen,
        isCreateSellerModalOpen, setIsCreateSellerModalOpen,
        isCreateAdminModalOpen, setIsCreateAdminModalOpen,
        isCreateDriverModalOpen, setIsCreateDriverModalOpen,
        isCreateGuiderModalOpen, setIsCreateGuiderModalOpen,
        passengerPlanId, setPassengerPlanId,
        currentDay, setCurrentDay,
        endDay, setEndDay,
        assignTripReservationId, setAssignTripReservationId,
        assignTripTourId, setAssignTripTourId,
        traspassTripReservationId, setTraspassTripReservationId,
        traspassTripTourId, setTraspassTripTourId,
        registerPassengersTripId, setRegisterPassengersTripId,
        registerCostsTripId, setRegisterCostsTripId,
        requirementsTripId, setRequirementsTripId,
        accountingSellerId, setAccountingSellerId,
        editTripId, setEditTripId,
        relationshipAgencyId, setRelationshipAgencyId,
        editReservationId, setEditReservationId,
        editPassengerData, setEditPassengerData,
        editTourData, setEditTourData,
        editDriverData, setEditDriverData,
        editGuiderData, setEditGuiderData,
        editSellerData, setEditSellerData,
        editAdminData, setEditAdminData,
        editAgencyData, setEditAgencyData,
        addPaymentData, setAddPaymentData,
        reservationDetails, setReservationDetails,
        viewPaymentAgencyId, setViewPaymentAgencyId
    }
    return <AdminProvider.Provider value={values}>{children}</AdminProvider.Provider>;
}

export const useAdmin = () => {
    return useContext(AdminProvider)
}