import { useState } from "react";
import AddNew from "./AddNew";
import List from "./List";
import SellersFilter from "./SellerFilter";
import { startOfToday } from "date-fns";
import SellerInfo from "./SellerInfo";

export default function Sellers() {
    const [day, setDay] = useState<Date>(startOfToday())
    const [endDay, setEndDay] = useState<Date>()
    const [sellerId, setSellerId] = useState<string>()
    return (
        <>
            <AddNew/>
            <SellersFilter
            day={day}
            setDay={setDay}
            endDay={endDay}
            setEndDay={setEndDay}
            setSellerId={setSellerId}
            />
            {
                sellerId &&
                <SellerInfo
                day={day}
                endDay={endDay}
                id={sellerId}
                />
            }
            <List/>
        </>
    )
}