import styled from "styled-components";
import Calendar from "../common/CalendarInput";
import { useSeller } from "../../context/seller";
import {FaHiking} from 'react-icons/fa'
import { IoPersonAddSharp } from 'react-icons/io5'

export default function Menu() {
    const {currentDay, setCurrentDay, setIsPassengersModalOpen, setIsCreatePassengerModalOpen} = useSeller()
    return (
        <Main>
            <div>
                <Calendar
                selectPastDays
                selectedDay={currentDay}
                setSelectedDay={setCurrentDay}
                calendarStyle={{border: 'none', margin: 'auto'}}
                containerStyle={{width: '100%'}}
                datesStyle={{left: '-15%'}}
                />
            </div>
            <div>
                <button title="List passengers"
                onClick={() => setIsPassengersModalOpen(true)}
                >
                    <FaHiking/>
                </button>
            </div>
            <div>
                <button title="Create passenger"
                onClick={() => setIsCreatePassengerModalOpen(true)}
                >
                <IoPersonAddSharp/>
                </button>
            </div>
        </Main>
    )
}

const Main = styled.div`
width: 100%;
height: 50px;
display: flex;
> div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    > button {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: #000;
        font-size: 16px;
        border: none;
        cursor: pointer;
        background-color: ${props => props.theme.whiteBackground};
    }
}
`