import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import { usePassenger } from "../../../context/passenger";
import { Dispatch, SetStateAction } from "react";

export default function Header({setIsInfoOpen, isInfoOpen}: {isInfoOpen: boolean | null, setIsInfoOpen: Dispatch<SetStateAction<boolean | null>>}) {
    const { user } = usePassenger()

    return (
        <Main>
            <h1>Welcome!, {user?.user?.passenger?.name}</h1>
            <div>
                <span>View details</span>
                <button
                onClick={() => setIsInfoOpen(!isInfoOpen)}
                >
                <IoIosArrowDown/>
                </button>
            </div>
        </Main>
    )
}

const Main = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
div {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}
h1 {
    font-size: 20px;
    font-weight: 400;
}
button {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
`