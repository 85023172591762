import styled, {keyframes} from "styled-components";
import { AiOutlineCloseCircle } from "react-icons/ai"
import { useAdmin } from "../../../context/admin";
import React, { ChangeEvent, useEffect, useState } from "react";
import * as CommonTypes from '../../../types/common.types'
import GeneralInput from "../../common/GeneralInput";
import insert from "../../../utils/insert";
import AdminService from "../../../services/admin.service";
import {add} from "date-fns";
import { useGlobal } from "../../../context/global";

export default function RegisterCosts() {
    const { setMessage } = useGlobal()
    const {setRegisterCostsTripId, registerCostsTripId, setCurrentDay} = useAdmin()
    interface CostInt extends Partial<CommonTypes.TripCost> {
        index: number
    }
    const [data, setData] = useState<CostInt[]>([])
    useEffect(() => {
        async function getCosts() {
            try {
                const trip = (await AdminService.getTrip(registerCostsTripId)).data
                setData(trip.costs || [])
            } catch (error) {
                console.log(error)
            }
        }
        getCosts()
    }, [])
    function addCost() {
        var maxValue = data.reduce(function(max, current) {
            return (max.index > current.index) ? max : current;
        }, data[0]);
        const newIndex = maxValue?.index || 0
        setData([...data, {name: '', index: newIndex + 1}])
    }
    function onChange(e: ChangeEvent<HTMLInputElement>, idx: number) {
        const name = e.target.name
        let value: string | number = e.target.value
        if (e.target.type === 'number') value = Number(value)
        const oldItem = data.find(item => item.index === idx)
        const newItem = {...oldItem, [name]: value}
        const filteredList = data.filter(item => item.index !== idx)
        const newList = insert(filteredList, idx, newItem)
        setData(newList)
    }

    const removeIdxKey = (arr: CostInt[]) => {
        const keyToRemove = "index";
        return arr.map(obj => {
            const { [keyToRemove]: removedKey, ...rest } = obj;
            return rest;
        });
    };

    async function updateCosts() {
        try {
            const newData = removeIdxKey(data) as CommonTypes.TripCost[]
            const response = await AdminService.updateCosts(newData, registerCostsTripId)
            setMessage({type: 'success', content: response.data})
            setTimeout(() => {
                setMessage(null)
            }, 2500)
            await setCurrentDay((prevDay: Date) => add(prevDay, {days: 1}))
            await setCurrentDay((prevDay: Date) => add(prevDay, {days: -1}))
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Main>
            <Modal>
                
                <button type="button" className="button" onClick={addCost}>
                    <span className="button__text">Add Cost</span>
                    <span className="button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round" stroke="currentColor" height="24" fill="none" className="svg"><line y2="19" y1="5" x2="12" x1="12"></line><line y2="12" y1="12" x2="19" x1="5"></line></svg></span>
                </button>
                <div className="data">
                {
                    data.sort((a,b) => a.index - b.index).map((cost, idx) => {
                        return <div key={idx}>
                            <GeneralInput
                            name="name"
                            value={cost.name}
                            label="Name"
                            onChange={e => onChange(e, cost.index)}
                            />
                            <GeneralInput
                            name="value"
                            type="number"
                            value={cost.value || ''}
                            label="Value"
                            onChange={e => onChange(e, cost.index)}
                            />
                        </div>
                    })
                }
                </div>
                <button type="submit"
                onClick={updateCosts}
                >Register costs</button>
                <div className="close" title="close"
                    onClick={() => {
                        setRegisterCostsTripId(null)
                    }}
                >
                    <AiOutlineCloseCircle/>
                </div>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
    `

const Modal = styled.div`
width: 80%;
max-height: 75%;
min-height: 400px;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
padding-top: 50px;
animation: ${showUpModal} .3s ease forwards;
.data {
    margin: 20px 0;
    > div {
        margin-bottom: 20px;
        display: flex;
        gap: 10px;
        > div {
            flex: 1;
        }
    }
}
.button {
  position: relative;
  width: 150px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.yellowDark};
  background-color: ${props => props.theme.yellowDark};
}

.button, .button__icon, .button__text {
  transition: all 0.3s;
}

.button .button__text {
  transform: translateX(30px);
  color: #fff;
  font-weight: 600;
}

.button .button__icon {
  position: absolute;
  transform: translateX(109px);
  height: 100%;
  width: 39px;
  background-color: ${props => props.theme.yellowDark};
  display: flex;
  align-items: center;
  justify-content: center;
}

.button .svg {
  width: 30px;
  stroke: #fff;
}

.button:hover {
  background: ${props => props.theme.yellow};
}

.button:hover .button__text {
  color: transparent;
}

.button:hover .button__icon {
  width: 148px;
  transform: translateX(0);
}

.button:active .button__icon {
  background-color: ${props => props.theme.yellowDark};
}

.button:active {
  border: 1px solid ${props => props.theme.yellowDark};
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
button[type=submit] {
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

button[type=submit]::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

button[type=submit]:hover {
    color: ${props => props.theme.white};
}

button[type=submit]:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

button[type=submit]:hover::before {
    top: -30px;
    left: -30%;
}
`